import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
// import myImg from "/Services/sec3/image.png"
function AdsSectionFour() {

  const { lg, } = useMedia()

  return (
    <section className="py-16 md:py-32 flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row flex-col-reverse items-center justify-center xl:gap-11">
          

          <div className="ads-sec2-card lg:h-[790px]  lg:w-5/12 w-9/12  space-y-6 lg:mb-10 ">
          {/* Search icon and heading container   */}
          <div className="flex  gap-4 lg:ml-16 justify-center">
            {/* <img
              src="Services/seo-sec2-search.png"
              className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 flex-shrink-0"
              alt="Search Icon"
            /> */}
            <h2 className="text-[21px] lg:text-[31px] w-[325px] font-light text-left text-[#DEE2E6] leading-tight">
            Event-Specific Advertising Solutions
            </h2>

          </div>
          <div className=" flex flex-col items-center ">
            <div >
              <div className="flex items-center gap-4 mb-4" >
                <LazyImage
                  src="/Services/ads/sec4/popper.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[14px] lg:text-[19px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Event Promotion
                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Tailored advertising strategies for specific events such as product launches, trade shows, or seasonal promotions.



              </p>
            </div>
            <div >
              <div className="flex items-center gap-4 mb-4" >
                <LazyImage
                  src="/Services/ads/sec4/rank.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[14px] lg:text-[19px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Performance Analysis
                                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Post-event analysis to measure the effectiveness of the advertising efforts and gather insights for future events.



              </p>
            </div>
            <div className="mb-4 ">
              <div className="flex items-center gap-4 mb-4" >
                <LazyImage
                  src="/Services/ads/sec4/location.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[14px] lg:text-[19px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Targeted Outreach
                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Using demographic and geographic targeting to reach the right audience for your event.



              </p>
            </div>

          </div>
          {/* Description text */}

        </div>
        <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? <LazyImage

              src="/Services/ads/sec4/image.png"
              alt="SEO Analysis on Mobile"
              clss="relative rounded-2xl h-[728px] w-[512px] xl:w-[546px] xl:h-[780px] "
              // className="relative rounded-2xl lg:w-[546px] lg:h-[780px] lg:ml-[164px]"
            /> : <LazyImage

              src="/Services/ads/sec4/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl  h-[260px] w-[356px] "
            />}

          </div>
        </div>
        
      </div>
    </section>
  );
}

export default AdsSectionFour;
