import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ContactForm from './ContactForm';
import {display, styled} from '@mui/system'
import { TabTitle } from '../utils/GeneralFunctions';
import { Directions } from '@mui/icons-material';

function Contact() {
  TabTitle("Contact Us")
  const Text = styled(Typography)(({ theme }) => ({
    fontFamily: "blinker",
    fontWeight: 400,
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "24px",
    },
  }));
  
  return (
    <div style={{
      overflow:'hidden'
    }}>
    <div role="presentation">
    
    </div>
    <Box container sx={{
      
      display:'flex',
      
       overflow:'hidden',
      flexDirection:'column',
      alignItems:'center',
      width:'100%',
      textAlign:'center',
      mb:2,
      mt:{xs : 20 , md : 5}
    }}>
    <Grid container sx={{
      mt:5,
      
      // width:'100%',
      display: 'flex',
      height: '100vh',
      alignItems: 'center',
      textAlign: 'center',
      backgroundImage: 'url(contactBack.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: {xs: 'bottom 100vh right 0' , md:'bottom 0px right 0'},
      // backgroundSize: '95vh',
      // alignContent: 'stretch',
      
    }}>
      <Grid item xs={12} sx={{ alignItems:'center',display:'flex',justifyItems:'center',
      flexDirection:'column',}}>
        <Typography sx={{
          fontSize: { xs: '40px', md: '64px' },
          
          fontFamily: "Blinker",
          fontWeight:'800',// Responsive font size
          lineHeight:{md : '90px' , xs : '45px'},
          mb : {md : 0 , xs : 5}
        }}>
          Let’s Talk About Your Brand<br /> Ambitions
        </Typography>
        <Typography sx={{
          mt:{md:'80px' , xs : '20px'},
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '16px', md: '24px' },
          px:{md:'20px' , xs : '10px'}, 
          maxWidth:"70vw",
          mb:{md:"230px"}
        }}>
          Got a question, a request, or something you'd like to address? We’re here to help. Just fill out the form below, and we’ll get back to you as soon as we can.
        </Typography>
      </Grid>
        <div className='w-full flex flex-col justify-center align-center'>

      <Grid item xs={12} md={4} sx={{
        display:'flex',
        // mx:{xs : '0px' , md : 0},
        flexDirection:'column',
        alignItems:{md : 'center', xs : 'center'}
      }}>
        <Typography sx={{
          marginTop: {md:'-150px' ,xs : 0} ,
          fontFamily: 'Blinker',
          fontSize: { xs: '20px', md: '56px' }, 
          fontWeight: 'regular',
          // lineHeight:'60px',
          textAlign:'left',
          marginLeft:{xs:"50px" , md:"20vw"},
          minWidth:{md:"500px !important"},
        }}>

         {/* <span style={{marginLeft:'-60px'}}> Fuel Your Ambitions</span><br /> */}
         {/*Timely Support<br /> */}
         We’ll get back to you within<strong> 24 hours</strong>
         {/* <span style={{marginLeft:'-30px'}}>Transform Your Future</span> */}
        </Typography>
        
      </Grid>

      <Grid item xs={12} md={4} sx={{
        mx:{xs : '0px' , md : 0}, 
        marginTop: {md:'-180px' ,xs : "40px"} ,
        alignItems:{md : 'center', xs : 'center'},
        display:'flex',
        marginLeft:{md:"60vw",xs : "60px"},
        flexDirection:'column',}}>
        <Typography sx={{
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '16px', md: '20px' },
          textAlign:'left',
          width:"450px",
          "@media (max-width: 1023px)":{
              width:"315px",
                  },
        }}>
          <strong>Reach Us Directly</strong>
          <br></br>
          
          E-mail: <a
                    style={{
                      // display: "inline",
                      fontFamily: "blinker",
                      // fontWeight: "800",
                      textDecoration:'underline',
                      color:'#fff'
                    }}
                    href="mailto:info@beverly.digital"
                  >
                    {/* <Text
                      sx={{
                        // display: "inline",
                      }}
                    > */}
                      info@beverly.digital
                    {/* </Text> */}
                  </a>
                  <br />
          <a style={{
            textDecoration:'none',
            color:'#fff'
          }} href='tel:+16475599797'>Phone: +1647-559-9797</a>
          <p style={{
                      fontFamily: "blinker",
                      color:'#fff'
                    }}>Office Hours: Monday to Friday, 8 AM – 5 PM (EST)</p>
          <p style={{
                      fontFamily: "blinker",
                      color:'#fff'
                    }}>Address:
                    Beverly Digital Agency
                    123 Main Street, Toronto, ON, Canada</p>
        </Typography>
      </Grid>

      </div>
    </Grid>
    
    <Grid xs={12} md={12} lg={5} sx={{

      textAlign:'center',
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      mb:30,
      mt:{md : 30 , xs : 10},
      mx:{md : '0' , xs : '20px'},
      boxShadow:'0px 0px 0px #DADDE4',
      borderRadius:12
      }    }>
      <Typography sx={{
          
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '36px', md: '64px' },
          fontWeight: '600',
        }}> Get in touch</Typography>
        <Typography sx={{
          
          fontFamily: 'Blinker, sans-serif',
          fontSize: { xs: '35px', md: '22vpx' }, 
          fontWeight: '200',
          mb:10

        }}> Reach out, and let's create a universe of possibilities together!</Typography>
      <Grid sx={{
        mb:2,
      }}  container xs={11} md={11} lg={12}> <ContactForm /></Grid>

      </Grid>
    
    </Box>
    </div>
  );
}

export default Contact;


// import { Box, Grid, Typography } from '@mui/material';
// import React from 'react';
// import ContactForm from './ContactForm';
// import {styled} from '@mui/system'
// import { TabTitle } from '../utils/GeneralFunctions';

// function Contact() {
//   TabTitle("Contact Us")
//   const Text = styled(Typography)(({ theme }) => ({
//     fontFamily: "blinker",
//     fontWeight: 400,
//     [theme.breakpoints.up("xs")]: {
//       fontSize: "14px",
//     },
//     [theme.breakpoints.up("sm")]: {
//       fontSize: "14px",
//     },
//     [theme.breakpoints.up("md")]: {
//       fontSize: "20px",
//     },
//     [theme.breakpoints.up("lg")]: {
//       fontSize: "24px",
//     },
//   }));
  
//   return (
//     <div style={{
//       overflow:'hidden'
//     }}>
//     <div role="presentation">
    
//     </div>
//     <Box container sx={{
      
//       display:'flex',
      
//        overflow:'hidden',
//       flexDirection:'column',
//       alignItems:'center',
//       width:'100%',
//       textAlign:'center',
//       mb:2,
//       mt:{xs : 20 , md : 5}
//     }}>
//     <Grid container sx={{
//       mt:5,
      
//       // width:'100%',
//       display: 'flex',
//       height: '100vh',
//       alignItems: 'center',
//       textAlign: 'center',
//       backgroundImage: 'url(contactBack.png)',
//       backgroundRepeat: 'no-repeat',
//       backgroundPosition: {xs: 'bottom 100vh right 0' , md:'bottom 0px right 0'},
//       // backgroundSize: '95vh',
//       // alignContent: 'stretch',
      
//     }}>
//       <Grid item xs={12} sx={{ alignItems:'center',display:'flex',justifyItems:'center',
//       flexDirection:'column',}}>
//         <Typography sx={{
//           fontSize: { xs: '40px', md: '64px' },
          
//           fontFamily: "Blinker",
//           fontWeight:'800',// Responsive font size
//           lineHeight:{md : '90px' , xs : '45px'},
//           mb : {md : 0 , xs : 5}
//         }}>
//           Talk to us about your  brand<br /> ambitions
//         </Typography>
//         <Typography sx={{
//           mt:{md:'80px' , xs : '20px'},
//           fontFamily: 'Blinker, sans-serif',
//           fontSize: { xs: '16px', md: '24px' },
//           px:{md:'20px' , xs : '10px'} // Responsive font size
//         }}>
//           We bring a diversity of talent, experience, and cultural perspective to all of our work
//         </Typography>
//       </Grid>

//       <Grid item xs={12} md={4} sx={{mx:{xs : '24px' , md : 0}, marginBottom: -2 ,marginTop: {md:'-150px' ,xs : 0} , alignItems:{md : 'center', xs : 'left'},display:'flex',
//       flexDirection:'column',  }}>
//         <Typography sx={{
//           fontFamily: 'Blinker',
//           textAlign:'left',
//           fontSize: { xs: '24px', xl: '56px', lg:'48px' }, // Responsive font size
//           // fontVariant: 'petite-caps',
//           pl:5
//         }}>
//           Beverly  is located at   <strong>Toronto</strong>
//         </Typography>
//       </Grid>

//       <Grid item xs={12} md={4} sx={{mx:{xs : '24px' , md : 0}, marginTop: {md:'-150px' ,xs : -10} ,alignItems:{md : 'center', xs : 'left'},display:'flex',
//       flexDirection:'column',}}>
//         <Typography sx={{
//           fontFamily: 'Blinker, sans-serif',
//           fontSize: { xs: '16px', md: '24px' },
//           lineHeight:'60px',
//           textAlign:'left',
//            // Responsive font size
//         }}>
//           Say hello<br />
          
//           E-mail: <a
//                     style={{
//                       display: "inline",
//                       fontFamily: "blinker",
//                       fontWeight: "800",
//                       textDecoration:'underline',
//                       color:'#fff'
//                     }}
//                     href="mailto:info@beverly.digital"
//                   >
//                     <Text
//                       sx={{
//                         display: "inline",
//                       }}
//                     >
//                       info@beverly.digital
//                     </Text>
//                   </a>
//                   <br />
//           <a style={{
//             textDecoration:'none',
//             color:'#fff'
//           }} href='tel:+16475599797'>+1647-559-9797</a>
//         </Typography>
//       </Grid>

//       <Grid item xs={12} md={4} sx={{
//         display:'flex',
//         mx:{xs : '24px' , md : 0},
//         flexDirection:'column',
//         alignItems:{md : 'center', xs : 'left'}
//       }}>
//         <Typography sx={{
//           marginTop: {md:'-150px' ,xs : -10} ,
//           fontFamily: 'Blinker, sans-serif',
//           fontSize: { xs: '20px', md: '26px' }, // Responsive font size
//           fontWeight: 'regular',
//           lineHeight:'60px',
//           textAlign:'left'
//         }}>

//          {/* <span style={{marginLeft:'-60px'}}> Fuel Your Ambitions</span><br /> */}
//          <span>Directions<br />
//          Media<br />
//          Alex Mangiola</span><br />
//          {/* <span style={{marginLeft:'-30px'}}>Transform Your Future</span> */}
//         </Typography>
        
//       </Grid>
      
//     </Grid>
//     <Grid xs={12} md={12} lg={5} sx={{

//       textAlign:'center',
//       display:'flex',
//       flexDirection:'column',
//       alignItems:'center',
//       mb:30,
//       mt:{md : 30 , xs : 10},
//       mx:{md : '0' , xs : '20px'},
//       boxShadow:'0px 0px 0px #DADDE4',
//       borderRadius:12
//     }    }>
//     <Typography sx={{
          
//           fontFamily: 'Blinker, sans-serif',
//           fontSize: { xs: '36px', md: '64px' }, // Responsive font size
//           fontWeight: '600',
//         }}> Get in touch</Typography>
//         <Typography sx={{
          
//           fontFamily: 'Blinker, sans-serif',
//           fontSize: { xs: '35px', md: '22vpx' }, // Responsive font size
//           fontWeight: '200',
//           mb:10

//         }}> Reach out, and let's create a universe of possibilities together!</Typography>
//        <Grid sx={{
//         mb:2,
//        }}  container xs={11} md={11} lg={12}> <ContactForm /></Grid>

//     </Grid>
    
//     </Box>
//     </div>
//   );
// }

// export default Contact;
