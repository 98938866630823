// import mockData from "./mockData.json";

const mockData = {
  title: "Enhancing Online Presence for a Senior Care Facility",
  brief: "This case study explores how Beverly empowered SilverComfort Living, a senior care facility in Ontario, to cultivate a thriving online presence and significantly increase occupancy rates.",
  challenges: [
    {
      title: "Targeting Seniors and Their Families in the Digital Age",
      details:
        "SilverComfort Living, known for its exceptional care and warm environment, struggled to attract new residents through digital channels. Their target audience, seniors and their adult children, primarily relied on digital resources for senior care research.",
    },
    {
      title: "Building Trust Through Virtual Tours",
      details:
        "With the rise of remote options, showcasing the facility's warmth and amenities virtually became crucial.",
    },
  ],
  solutions: [
    {
      title: "Mobile-First Website Redesign",
      details:
        "A user-friendly website optimized for all devices ensured smooth navigation for tech-savvy adults and seniors alike.",
    },
    {
      title: "Local SEO Optimization",
      details:
        "Strategic keyword targeting increased SilverComfort's visibility in local search results for 'senior care facilities Ontario.'",
    },
    {
      title: "Engaging Social Media Presence",
      details:
        "BDM created and managed social media profiles to share resident stories, facility updates, and valuable senior care information, fostering a sense of community.",
    },
  ],
  results: [
    "150% Increase in Website Traffic",
    "200% Growth in Social Media Engagement",
    "75% Increase in Website Inquiries",
    "40% Boost in Occupancy Rates Within 6 Months",
    "30% of New Inquiries from Brochures",
  ],
  ctaSecond: {
    text: "Ready to take your senior care facility to the next level? Contact us today for a customized proposal tailored to your needs.",
    image: "/Services/sec-section2.png",
  },
  successfulBusiness: {
    title: "Successful Business in the Past",
    description:
      "SilverComfort Living is a prime example of how digital marketing can transform a business. By leveraging SEO, content marketing, and social media strategies, SilverComfort achieved a 40% increase in occupancy rates within just 6 months.",
  },
  conclusion:
    "BDM's comprehensive strategy transformed SilverComfort's digital presence, leading to increased brand awareness, trust, and ultimately, a thriving senior care community.",
};

const Blog = () => {
  return (
    <div className="p-6 max-w-4xl mx-auto ">
      <h1 className="text-3xl font-bold mb-4 mt-16">{mockData.title}</h1>
      <p className="mb-4">{mockData.brief}</p>
      <section>
        <h2 className="text-2xl font-semibold mb-2">Challenges</h2>
        {mockData.challenges.map((challenge, index) => (
          <div key={index} className="mb-4">
            <h3 className="text-xl font-medium">{challenge.title}</h3>
            <p>{challenge.details}</p>
          </div>
        ))}
      </section>
      <section>
        <h2 className="text-2xl font-semibold mb-2">Solutions</h2>
        {mockData.solutions.map((solution, index) => (
          <div key={index} className="mb-4">
            <h3 className="text-xl font-medium">{solution.title}</h3>
            <p>{solution.details}</p>
          </div>
        ))}
      </section>
      <section className="flex items-center my-8 p-4 rounded-lg shadow-lg bg-slate-600 bg-opacity-30 ">
        <div className="w-1/2 pr-4">
          <p className="text-lg">{mockData.ctaSecond.text}</p>
        </div>
        <div className="w-1/2">
          <img
            src={mockData.ctaSecond.image}
            alt="Contact Us"
            className="rounded-lg shadow-md"
          />
        </div>
      </section>
      <section>
        <h2 className="text-2xl font-semibold mb-2">
          {mockData.successfulBusiness.title}
        </h2>
        <p>{mockData.successfulBusiness.description}</p>
      </section>
      <section>
        <h2 className="text-2xl font-semibold mb-2">Results</h2>
        <ul className="list-disc pl-6">
          {mockData.results.map((result, index) => (
            <li key={index}>{result}</li>
          ))}
        </ul>
      </section>
      <section>
        <h2 className="text-2xl font-semibold mb-2">Conclusion</h2>
        <p>{mockData.conclusion}</p>
      </section>
    </div>
  );
};

export default Blog;
