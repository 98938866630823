// src/components/PostGrid.js
import '../Library/css/Blogs.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton'; // Fixing Skeleton import
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { TabTitle } from '../utils/GeneralFunctions';
import LazyImage from './lazyImage';

const PathPostGrid = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(null);
  const { path } = useParams();
  useEffect(() => {
    axios.get(apiUrl+`/posts?category=blogs&tag=${path}`)
      .then(response => {
        setPosts(response.data);
        setLoading(false);
      })
      .catch(error => {
        // console.error('There was an error fetching the posts!', error);
        navigate("/404", { replace: true });
        setLoading(false);
      });
  }, [apiUrl]);
  TabTitle("Blogs")
  return (
    <>
    <div role="presentation">
    </div>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight:'100vh',
        mt: 30,
        ml:{md:4, xs:'0'}
      }}
    >
      
      <Grid
        container
        maxWidth={1200}
        sx={{
          height: 'auto',
          display: 'flex',
          

          flexDirection:{md : 'row',xs:'column'},
          // px: { xs: 0, md: 6 , lg:12, xl:20},
          alignItems:'center'

        }}
      >
        {loading ? (
          Array.from(new Array(6)).map((_, index) => (
            <Grid
              item
              xs={12}
              sm={4}
              md={4}
              lg={4}
              key={index}
              sx={{
                // Add extra margin to all items in the second column
                mb: 10,
              }}
            >
              <Card
                sx={{
                  p: 3,
                  backgroundColor: '#182233',
                  width: '75%',
                  borderRadius: 4,
                  boxShadow: 3,
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
                className='customBlogCard'
              >
                <Skeleton  variant="rectangular" height={250} />
                <CardContent sx={{ mt: 2 }}>
                  <Skeleton width="80%" />
                  <Skeleton width="60%" />
                  <Skeleton width="40%" />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          posts.map((post, index) => {
            const content = post.content;
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            const firstImage = post.banner;
            const description = post.description.slice(0, 100) + '...';

            return (
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                xs={12}
                key={post.id}
                
                sx={{
                 // Add extra margin to all items in the second column
                  mb: 10,
                }}
              >
                <Link
                  style={{ textDecoration: 'none' }}
                  to={`/${post.subPath}/${post.path}`}
                >
                  <Card
                    sx={{
                      p: 3,
                      backgroundColor: '#344051',
                      width: '350px',
                      borderRadius: 4,
                      
                      boxShadow: 3,
                      height: '495px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    className='customBlogCard'
                  >
                    {firstImage && (
                      <CardMedia
                        component="img"
                        
                        style={{
                          width:'310px ',
                          height:'220px'
                        }}
                        image={firstImage}
                        alt={post.title}
                        sx={{ borderRadius: 2 }}
                      />
                    )}
                    <CardContent sx={{ mt: 2 }}>
                      <Typography variant="h6" component="div" sx={{
                        color:'#fff',
                        fontFamily:'blinker',

                        fontSize:'20px',
                        fontWeight:600,
                      }} gutterBottom>
                        {post.title}
                      </Typography>
                      <Typography sx={{
                        color:'#E3E3E3',
                        fontFamily:'blinker',

                        fontSize:'16px',
                        fontWeight:300,
                      }} variant="body2" color="text.secondary" mb={2}>
                        {description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            );
          })
        )}
      </Grid>
      <LazyImage
      alt=''
        src="halfcircleblogs.png"
        style={{
          position: 'fixed',
          zIndex: -1,
          bottom: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      />
    </Box>
    </>
  );
};

export default PathPostGrid;
