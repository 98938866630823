import React, { useState, useEffect } from "react";
import {
  Chip,
  Button,
  Box,
  Container,
  Typography,
  LinearProgress,
  TextField,
} from "@mui/material";
import FlashOnIcon from "@mui/icons-material/ArrowBack"; // Import the icon for Back button
import jsonData from "./quote.json"; // Ensure this path is correct
import axios from "axios";
import { TabTitle } from "../utils/GeneralFunctions";



function QuoteNew() {
  TabTitle("Get Proposal")
  const apiUrl = process.env.REACT_APP_API_URL;
  // State to track the current question index
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  // State to store selected answers
  const [answers, setAnswers] = useState([]);

  // State for fix error about null selected answers
  const [error, setError] = useState(false);

  // State to store the questions from the JSON file
  const [questions, setQuestions] = useState([]);
  // State to track if additional questions have been loaded
  const [additionalQuestionsLoaded, setAdditionalQuestionsLoaded] = useState(false);
  // State to store the final result (questions and answers)
  const [finalResults, setFinalResults] = useState([]);

  // State for some questions to have a singleAnswer
  const [singleAnswer, setSingleAnswer] = useState(false);

  // State for first question if choose other option as an answer question
  const [other, setOther] = useState(false);

  // State for dropdown box in other options
  const [otherDd, setOtherDd] = useState(false);

  // State for first card to start the question
  const [start, setStart] = useState(false);

  // State for first question if choose other option  as an answer question and text about other options
  const [otherName, setOtherName] = useState('choose your industry');

  // State for submit form after all  questions are answered
  const [form, setForm] = useState(false);

  // State for show business form 
  const [businessForm, setBusinessForm] = useState(false);

  // Return All Values
  const [all, setAll] = useState([]);

  // State to get fullname input value
  const [fullname, setFullname] = useState('');
  const [email, setEmail] = useState('');
  const [business, setBusiness] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [webUrl, setWebUrl] = useState('');
  const [socialMediaHandles, setSocialMediaHandles] = useState('');
  const [businessLocation, setBusinessLocation] = useState('');
  const [mainCompetitors, setMainCompetitors] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  const [errors, setErrors] = useState({});
  // const currentQuestion = questions[currentQuestionIndex];
  useEffect(() => {
    if(questions.length !== 0 ){

    
    if (questions[currentQuestionIndex].id >= 1 && questions[currentQuestionIndex].id <= 99) {
      setSectionName('General');
    } else if (questions[currentQuestionIndex].id >= 100 && questions[currentQuestionIndex].id <= 199) {
      setSectionName('Branding and identity building');
    } else if (questions[currentQuestionIndex].id >= 200 && questions[currentQuestionIndex].id <= 299) {
      setSectionName('Content creation and strategy');
    } else if (questions[currentQuestionIndex].id >= 300 && questions[currentQuestionIndex].id <= 399) {
      setSectionName('Web Design');
    } else if (questions[currentQuestionIndex].id >= 400 && questions[currentQuestionIndex].id <= 499) {
      setSectionName('SEO');
    } else if (questions[currentQuestionIndex].id >= 500 && questions[currentQuestionIndex].id <= 599) {
      setSectionName('Ads');
    } else {
      setSectionName(''); // Optional: Default case if no range matches
    }
  }
  }, [questions[currentQuestionIndex]]);
  // State to show the end page of proposal
  const [endPage, setEndPage] = useState(false);

  // State to show the image of selected answer in branding
  const [selectedImage, setSelectedImage] = useState("");

  // State to show the default image of branding
  const [defaultImage, setDefaultImage] = useState(true);


  // First card to ready for start to answer the questions
  const startQuestion = () => {
    window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: "gtm.click"            
        });
    setStart(true);
  }

  // Fetch initial questions from JSON file on component mount
  useEffect(() => {
    setQuestions(jsonData.quote.questions.slice(0, 7)); // Load up to question 6 initially
  }, []);

  // const [otherQuestion2, setOtherQuestion2] = useState(false);

  // Calculate progress value (percentage of questions answered)
  let progressValue = null;

  if (currentQuestionIndex === 6) {
    progressValue = 0;
  }

  else {
    if (start === false) {
      progressValue = ((currentQuestionIndex + 0) / questions.length) * 100;
    }
    else {
      progressValue = ((currentQuestionIndex + 1) / questions.length) * 100;
    }
  }

  // Handle selecting/deselecting options (multiple selection allowed)
  const handleOptionChange = (optionId, optionText) => {
    const currentQuestion = questions[currentQuestionIndex];

    // If the question has not multiple answers , set singleAnswer to true and can just answer one option
    if (currentQuestion.isMultiple === false) {
      if (currentQuestion.id === 102) {
        setSingleAnswer(true);

        //setSelectedImage(currentQuestion.image)
        const currentAnswers = answers;
        let newAnswers = [...currentAnswers];

        // Toggle the option selection (add or remove)
        if (newAnswers.includes(optionId)) {
          newAnswers = newAnswers.filter((id) => id !== optionId); // Remove if already selected
        } else {
          newAnswers.push(optionId); // Add if not selected
        }


        // Update the answers state for the current question
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestionIndex] = newAnswers;
        setAnswers(updatedAnswers);

        // Update the finalResults with question text and selected answer text

        const selectedAnswers = currentQuestion.options
          .filter((option) => updatedAnswers[currentQuestionIndex]?.includes(option.id))
          .map((option) => option.text);

        const updatedResults = [...finalResults];
        updatedResults[currentQuestionIndex] = {
          question: currentQuestion.question,
          answers: selectedAnswers,
        };

        setFinalResults(updatedResults);

        // Log for debugging and test for single answer 
        console.log(singleAnswer)

        // About Branding section
        let image = currentQuestion.options.filter((x) => x.id === optionId)
        setSelectedImage(image[0].image)

        setOtherName(optionText);
        setOtherDd(false);
        setError(false);
        setDefaultImage(false);
      }
      else {
        setSingleAnswer(true);

        const currentAnswers = answers;
        let newAnswers = [...currentAnswers];

        // Toggle the option selection (add or remove)
        if (newAnswers.includes(optionId)) {
          newAnswers = newAnswers.filter((id) => id !== optionId); // Remove if already selected
        } else {
          newAnswers.push(optionId); // Add if not selected
        }


        // Update the answers state for the current question
        const updatedAnswers = [...answers];
        updatedAnswers[currentQuestionIndex] = newAnswers;
        setAnswers(updatedAnswers);

        // Update the finalResults with question text and selected answer text

        const selectedAnswers = currentQuestion.options
          .filter((option) => updatedAnswers[currentQuestionIndex]?.includes(option.id))
          .map((option) => option.text);

        const updatedResults = [...finalResults];
        updatedResults[currentQuestionIndex] = {
          question: currentQuestion.question,
          answers: selectedAnswers,
        };

        setFinalResults(updatedResults);

        // Log for debugging and test for single answer 
        // console.log(singleAnswer)

        // About First Question if choose other
        if (currentQuestionIndex === 0) {
          console.log(selectedAnswers)

          if (selectedAnswers[0] === 'Other') {
            
            setOther(true);
            // setOtherQuestion2(true);
          }
        }

        setOtherName(optionText);
        setOtherDd(false);
        setError(false);
      }
    }

    // If the question has multiple answers , set singleAnswer to false by default and can answer multiple options
    else {
      const currentAnswers = answers[currentQuestionIndex] || [];
      let newAnswers = [...currentAnswers];

      // Toggle the option selection (add or remove)
      if (newAnswers.includes(optionId)) {
        newAnswers = newAnswers.filter((id) => id !== optionId); // Remove if already selected
      } else {
        newAnswers.push(optionId); // Add if not selected
      }

      // Update the answers state for the current question
      const updatedAnswers = [...answers];
      updatedAnswers[currentQuestionIndex] = newAnswers;
      setAnswers(updatedAnswers);

      const selectedAnswers = currentQuestion.options
        .filter((option) => updatedAnswers[currentQuestionIndex]?.includes(option.id))
        .map((option) => option.text);

      const updatedResults = [...finalResults];
      updatedResults[currentQuestionIndex] = {
        question: currentQuestion.question,
        answers: selectedAnswers,
      };
      setFinalResults(updatedResults);

      setOtherName(optionText);
      setOtherDd(false);
      setError(false);
    }

  };

  const OpenOtherOption = () => {
    setOtherDd((prevState) => !prevState);
  }

  // Move to the next question
  const nextQuestion = () => {
    
    const currentAnswers = answers[currentQuestionIndex] || [];
    let newAnswers = [...currentAnswers];

    const updatedAnswers = [...answers];
    updatedAnswers[currentQuestionIndex] = newAnswers;
    setAnswers(updatedAnswers);

    const selectedAnswers = currentQuestion.options
      .filter((option) => updatedAnswers[currentQuestionIndex]?.includes(option.id))
      .map((option) => option.text);

    if (currentQuestionIndex === 0) {
      if (selectedAnswers[0] === 'Other') {
        setError(true);
      }
      else {
        if (selectedAnswers.length === 0) {
          setError(true);
          setOther(false);
          // setOtherQuestion2(true);
        }

        else {
          const updatedResults = [...finalResults];

          updatedResults[currentQuestionIndex] = {
            question: currentQuestion.question,
            answers: selectedAnswers,
          };

          if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            
          }
        }

        setOther(false);
        // setOtherQuestion2(false);
      }
    }
    else {
      if (selectedAnswers.length === 0) {
        setError(true);
      }

      else {
        const updatedResults = [...finalResults];

        updatedResults[currentQuestionIndex] = {
          question: currentQuestion.question,
          answers: selectedAnswers,
        };

        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
      }

      setOther(false);
      // setOtherQuestion2(false);
    }
  };

  // Move to the previous question
  const previousQuestion = () => {
    if (currentQuestionIndex > 0) {

      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
    
    if(currentQuestionIndex >= 4  ){
      const greaterNum = questions.find(question => question.id > 6);
      console.log(greaterNum.id,questions[currentQuestionIndex].id)
      if(greaterNum.id === questions[currentQuestionIndex].id){
        console.log("first")
      const filterdQ = questions.filter(question => question.id <= 6);
      setQuestions(filterdQ)
      setAdditionalQuestionsLoaded(false)
      // setQuestions(...questions.filter(question => question.id <= 6))
      // console.log(...questions)
    }
  }
  };

  // Submit final answers
  const submitAnswers = () => {
    // console.log(answers[4])
    
    if (currentQuestionIndex === 4 && !additionalQuestionsLoaded) {
      // setQuestions([])
      // 
      if (answers[4].includes(100)) {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...jsonData.quote.brand.filter((q) => [101,105, 102, 103, 104].includes(q.id)),
        ]);
      }
      if (answers[4].includes(200)) {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...jsonData.quote.content.filter((q) => [201, 202, 203, 204].includes(q.id)),
        ]);
      }
      if (answers[4].includes(300)) {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...jsonData.quote.web.filter((q) => [301, 303].includes(q.id)),
        ]);
      }
      if (answers[4].includes(400)) {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...jsonData.quote.seo.filter((q) => [401, 402, 403].includes(q.id)),
        ]);
      }
      if (answers[4].includes(500)) {
        setQuestions((prevQuestions) => [
          ...prevQuestions,
          ...jsonData.quote.ads.filter((q) => [501, 502, 504].includes(q.id)),
        ]);
      }
      setAdditionalQuestionsLoaded(true); // Ensure we don't load additional questions multiple times
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
    else {
      setForm(true);
    }
    progressValue = 0;
  };
const Send = () => {
  axios.post(`${apiUrl}/send-proposal`, { 'proposal': finalResults, 'name': fullname, 'email': email, 'companyName': business, 'phoneNumber': phoneNumber, 'websiteUrl': webUrl, 'socialMedia': socialMediaHandles, 'companyLocation': businessLocation, 'competitors': mainCompetitors, 'projectDescription': projectDesc }).then(res => {
    setEndPage(true);
  })
}
  // Get All values and save them on all state to use on backend
  const GetAll = async () => {
    const newErrors = {};
    const webUrlRegex = /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;
    if (!webUrl ||!webUrlRegex.test(webUrl)) newErrors.webUrl = 'Website Url is required';
    //if(!socialMediaHandles) newErrors.socialMediaHandles = "Socials Requierd"
    if(!businessLocation) newErrors.businessLocation = "Business Location Requierd"
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    else{

    
    setAll({ 'proposal': finalResults, 'name': fullname, 'email': email, 'companyName': business, 'phoneNumber': phoneNumber, 'websiteUrl': webUrl, 'socialMedia': socialMediaHandles, 'companyLocation': businessLocation, 'competitors': mainCompetitors, 'projectDescription': projectDesc });
    setTimeout(() => { Send() }, 1000)
    
  }
  }

  
  const ShowBusinessForm = () => {
    // if (fullname === null || email === null || business === null || phoneNumber === null) {
    //   alert("Please fill in all fields");
    // }
    //setErrors({});
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[0-9]\d{9,11}$/;

    if (!fullname) newErrors.fullname = 'Name is required';
    if (!email) {
      newErrors.email = 'Email is required';
    } if (!emailRegex.test(email)) {
      newErrors.email = 'Invalid email format , Currect : example@gmail.com';
    }
    if (!business) newErrors.business = 'Business is required';
    // if (!description) newErrors.description = 'Description is required';
    if (!phoneNumber) newErrors.phoneNumber = 'phoneNumber is required';
    if (phoneNumber && !phoneRegex.test(phoneNumber)) {
      newErrors.phoneNumber = 'Phone number must be between 10 to 12 digits';
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    else {
      window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'next button in get proposal',  // This should match the custom event name in GTM
            
        });
      setBusinessForm(true)
    }
  }

  // Get the current question
  const currentQuestion = questions[currentQuestionIndex];
  const [sectionName,setSectionName] = useState('Main')
  // If questions are not loaded yet, show a loading message
  if (questions.length === 0) {
    return <div>Loading questions...</div>;
  }
  
  

  return (
    <div style={{
      
    }}>
      {endPage && <div className="end-page">
        <p className="end-page-title lg:text-[68px] text-[50px]">
          Thanks for connecting!
        </p>
        <div className="end-page-card">
          Welcome to  <strong>BEVERLY</strong> ,   {all.name}!
        </div>
        <p className="end-page-text mx-4">
          We’ d love for you to get to know us
        </p>
        <p className="end-page-text-2 mx-4">
          Your information is being reviewed. We'll contact you within 3 business days.
        </p>
        <br />
        {console.log("Final Results:", all)}
      </div>}
      {!endPage && <div>
        {form && <div className="px-10 mt-50">
          {!businessForm &&
            <div>
              <p className="question-form-title pt-form-title">
                Tell us about <strong>yourself</strong>  :)
              </p>

              <div className="question-form-1 mt-50 mx-4">
                <div className="question-form-group">
                  <label className="question-form-label">Your Full Name*</label>
                  <TextField type="text" placeholder="Your Full Name*" required
                error={!!errors.fullname}
                helperText={errors.fullname} onChange={(e) => setFullname(e.target.value)} />
                </div>

                <div className="question-form-group">
                  <label className="question-form-label">business Email Address*</label>
                  <TextField error={!!errors.email}
                helperText={errors.email} required type="email" placeholder="DrPhilips@smilejob.com" onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div className="question-form-group">
                  <label className="question-form-label">Name of your company/Business*:</label>
                  <TextField error={!!errors.business}
                helperText={errors.business} required type="text" placeholder="Name of your company/Business*:" onChange={(e) => setBusiness(e.target.value)} />
                </div>

                <div className="question-form-group">
                  <label className="question-form-label">Phone Number*:</label>
                  <TextField fullWidth error={!!errors.phoneNumber}
                helperText={errors.phoneNumber} type="number" required typeof="tel" placeholder="Phone Number*:" onChange={(e) => setPhoneNumber(e.target.value)} />
                </div>

                <Box
                  className="btn-next-parent"
                  sx={{
                    // position: "absolute",
                    bottom: "10px",
                    right: "0",
                    // marginTop: "-40px",
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "column",
                    gap: "10px",
                    direction: "rtl"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={ShowBusinessForm}
                    className="btn-next">
                    Next
                  </Button>
                </Box>
              </div>
            </div>}

          {businessForm && <div>
            <p className="question-form-title pt-form-title">
              Tell us about your <strong>business</strong>  :)
            </p>
            <div className="form-business-flex">
              <div className="question-form mt-50 w-50">
                <div className="question-form-group w-full">
                  <label className="question-form-label text-sm lg:text-2xl">Website URL *:</label>
                  <TextField error={!!errors.webUrl}
                helperText={errors.webUrl} required type="text" placeholder="www." onChange={(e) => setWebUrl(e.target.value)} />
                </div>

                <div className="question-form-group w-full">
                  <label className="question-form-label text-sm lg:text-2xl">Social Media Handles:</label>
                  <TextField error={!!errors.socialMediaHandles}
                helperText={errors.socialMediaHandles} required type="text" placeholder="Social Media Handles" onChange={(e) => setSocialMediaHandles(e.target.value)} />
                </div>

                <div className="question-form-group w-full">
                  <label className="question-form-label text-sm lg:text-2xl">Location of Business*:</label>
                  <TextField error={!!errors.businessLocation}
                helperText={errors.businessLocation} required type="text" placeholder="Location of Business" onChange={(e) => setBusinessLocation(e.target.value)} />
                </div>

                <div className="question-form-group w-full">
                  <label className="question-form-label text-sm lg:text-2xl">Who are your main competitors?</label>
                  <textarea style={{
                    resize: 'none',
                  }} rows={5}   error={!!errors.mainCompetitors}
                helperText={errors.mainCompetitors} required placeholder="Who are your main competitors?" onChange={(e) => setMainCompetitors(e.target.value)} />
                </div>
              </div>

              <div className="question-form mt-50 w-50">
                <div className="question-form-group w-full">
                  <label className="question-form-label text-sm lg:text-2xl">Tell us more about your project?</label>
                  <textarea style={{
                    resize: 'none',
                  }}  error={!!errors.projectDesc}
                helperText={errors.projectDesc} required rows={14} placeholder="Tell us more about your project?" onChange={(e) => setProjectDesc(e.target.value)} />
                </div>

                <Box
                  className="btn-next-parent"
                  sx={{
                    bottom: "10px",
                    right: "0",
                    marginTop: "100px",
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: "column",
                    // gap: "10px",
                    direction: "rtl"
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={GetAll}
                    className="btn-next">
                    Next
                  </Button>
                </Box>
              </div>
            </div>
          </div>}
        </div>}

        {!form && <Container
          className="progress-bar-quote container mx-auto"
          maxWidth="md"
          sx={{
            
            minHeight: "100vh",
           // padding: "0",
            "@media (max-width: 1400px)": {
              padding: "0 0px", // Adjust padding for smaller screens
            },
            
               // Adjust padding for smaller screens
            
          }}
        >
          {/* Linear Progress Bar at the top */}
          <Box sx={{ width: "100%", marginTop: {md: "100px" , xs: '60px' } }}>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{
                height: "2px", // Custom height
                backgroundColor: "gray", // Color for the remaining questions
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#1BE4A6", // Color for the answered questions
                },
              }}
            />
          </Box>
          {/* Back button */}
          {currentQuestionIndex >= 1 ? (
            <div className="flex flex-row justify-between items-center"><Button
              variant="text"
              onClick={previousQuestion}
              startIcon={<FlashOnIcon />} // Add Flashback icon
              sx={{
                width: "150px",
                height: "50px",
                fontFamily: "blinker",
                fontSize: "16px",
                fontWeight: "bold",
                mt: {md : "40px", sm : '0px'},
                color: "#fff",
                "&:hover": {
                  // borderColor: "black",
                  // backgroundColor: "#f0f0f0",
                  color : '#10F9B1',
                  backgroundColor:'#182233'
                },
              }}
            >
              Back
            </Button>
            <Typography sx={{
              fontFamily:'blinker',
              fontWeight:'200',
              fontSize:{xs : '12px', lg:'20px' },
              color:'#BBC6F3',
              mr:4
            }}>{sectionName}</Typography>
            
            </div>
          ) : (<div style={{
            height: "90px",
            width: "150px",
            mt:  "60px",
          }}></div>)}

          {/* Other Option Question */}
          <div className={!other &&  'd-none'}>
            <Box
              className='other-box'
              sx={{
                textAlign: "center",
                width: "100%",
                marginTop: "10px", // Adjust for spacing under the progress bar
              }}
            >
              {/* Display current question */}
              <Typography
                className="question-box quote-title"
                gutterBottom
                sx={{
                  textAlign: "left",
                  marginTop: "80px",
                  fontFamily: "blinker",
                  minHeight:{xs:'250px',md:'0'},
                  mb:{xs : 0 , md : '25px' ,lg : '33px'},
                  fontWeight: "400",
                  fontSize: "56px",
                }} // Align question text to the left with margin from top
              >
                {currentQuestion.question}
              </Typography>

              {/* Render the options as chips */}
              <Box sx={{
                margin: "5px auto",
                width: "100%",
                color:
                  "#6C757D !important",
               
                borderRadius: "8px", // Rounded corners
                fontFamily: "Blinker-Light",
                fontSize: {md:"24px" ,sm : "20px"},
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#344051",
                fontWeight: "200",
                padding: "10px",
                height: "auto",
                textAlign: "left",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                transition: "0.3s",
                // "&:hover": {
                //   backgroundColor: "#fff",
                //   border: "1px solid #1BE4A6 !important",
                // },
              }} onClick={OpenOtherOption}>
                <p id="other-title" style={{
                  color:'#fff'
                }}>
                  {otherName}
                </p>
                <p className="pt-1">
                  <svg width="23" height="15" viewBox="0 0 23 15" fill="none" className={otherDd ? "close-other-option" : "open-other-option"} xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 14L11.5 2L22 14" stroke="#182233" stroke-width="2" />
                  </svg>
                </p>
              </Box>
              <Box className={otherDd ? "p-10" : "d-none"}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  gap: "4px",
                  justifyContent: "start",
                  flexWrap: "wrap",
                  marginTop: "10px",
                  // maxWidth: "90%",
                  maxHeight: "350px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  backgroundColor: "#212529 !important",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",
                  padding: "5px",
                }}
              >
                {currentQuestion.options.filter((option22) => option22.isOtherAnswer).map((option2) => (
                  <Chip
                    key={option2.id}
                    label={option2.text}
                    clickable
                    
                    onClick={() => handleOptionChange(option2.id, option2.text)}
                    sx={{
                      width: "100%",
                      margin: "5px",
                      color: answers[currentQuestionIndex]?.includes(option2.id)
                        ? "#1BE4A6 !important"
                        : "#fff !important", // Dynamic backgroundColor
                      border: answers[currentQuestionIndex]?.includes(option2.id)
                        ? "1px solid #1BE4A6"
                        : "1px solid #212529", // Change text color based on selection
                      borderRadius: "8px", // Rounded corners
                      fontFamily: "Blinker-Light",

                      fontSize: {lg:"24px" ,sm : "20px"},
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#212529",
                      fontWeight: "200",
                      padding: "10px",
                      height: "auto",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0)",
                        border: "1px solid #1BE4A6 !important",
                      },
                    }}
                  />
                ))}

                {currentQuestionIndex === 1 &&
                  <Chip
                    label='other'
                    clickable
                    onClick={() => setOther(true)}
                    sx={{
                      width: "100%",
                      margin: "5px",
                      color: "#182233 !important", // Dynamic backgroundColor
                      border: "1px solid #212529", // Change text color based on selection
                      borderRadius: "8px", // Rounded corners
                      fontFamily: "Blinker-Light",
                      fontSize: "24px",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#fff",
                      fontWeight: "200",
                      padding: "10px",
                      height: "auto",
                      transition: "0.3s",
                      "&:hover": {
                        backgroundColor: "#fff",
                        border: "1px solid #1BE4A6 !important",
                      },
                    }}
                  />

                }
              </Box>

              {/* Navigation buttons: Back and Next */}
              <Box
                className="btn-next-parent"
                sx={{
                  // position: "absolute",
                  bottom: "0",
                  right: "0",
                  marginTop: "0",
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "column",
                  gap: "10px",
                  direction: "rtl"
                }}
              >
                {/* {currentQuestionIndex.isMultiple  ? <Typography className="error-text"> Choose many as you like</Typography> : ''} */}
                {/* {!error &&  questions[currentQuestionIndex].isMultiple ? (

                  <p className="error-text">
                  Choose many as you like
                  </p> ): error && ( 
                  <p className="error-text">
                    Please Select One Option
                  </p>)} */}
                  {/* {!error &&  questions[currentQuestionIndex].isMultiple ? (

                  <p className="error-text">
                  Choose many as you like
                  </p> ): error && ( 
                  <p className="error-text">
                    Please Select One Option
                  </p>)} */}
                {/* Next button */}
                {currentQuestionIndex < questions.length - 1 ||
                  (additionalQuestionsLoaded && currentQuestionIndex < questions.length - 1) ? (
                  <Button
                    variant="contained"
                    onClick={nextQuestion}
                    className="btn-next"
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={submitAnswers}
                    className="btn-next">
                    Next
                  </Button>
                )}
              </Box>
            </Box>
          </div>
          {/* Other Option Question */}
                
          <div className={(other) ? "d-none" : ("px-10 md:-mt-20")}>
            <div>
              {/* Start Card */}
              <Container id="quote-text" className={start && 'd-none'}>
                <span>
                  Business not growing? let's fix that
                </span>
                <Typography
                  className="question-box quote-title"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    fontFamily: "blinker",
                    // minHeight:{xs:'350px',md:'0'},
                    fontWeight: "400",
                    fontSize: "56px",
                    marginTop: "20px",
                    mb:{xs : 0 , md : '25px' ,lg : '33px'},
                    // marginBottom: "0"
                  }}
                >
                  Ready to level-up your business?
                </Typography>
                <Typography
                  className="question-box quote-title-1"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    fontFamily: "blinker",
                    // minHeight:{xs:'350px',md:'0'},
                    fontWeight: "700",
                    fontSize: {xs:"48px",md:"64"},
                    mb:{xs : 0 , md : '25px' ,lg : '33px'},
                  }}
                >
                  We are.
                </Typography>
                <Button
                  variant="contained"
                  onClick={startQuestion}
                  sx={{
                    position:'relative',
                    mt:{xs : '20vh', md : 20},
                    minHeight:"80px",
                    maxHeight:{xs:"50px", md:"auto"},
                  }}
                  className="btn-next"
                >
                  <Typography sx={{
                    fontSize:{xs:"27px", md:"36px"},
                    fontFamily:"Blinker-Light",
                    minWidth:"115px",
                  }}>
                    I'M READY
                  </Typography>
                </Button>
              </Container>

              <div id="quote-body" className={(!start) ? "d-none" : ("lg:px-10  " )}>
                <Box
                  sx={{
                    textAlign: "center",
                    maxWidth: "1400px !important",
                    width: "100%",
                    marginTop: {lg: "30px" , xs : '-80px'}, // Adjust for spacing under the progress bar
                  }}
                >
                  {/* Display current question */}
                  <Typography
                    className="question-box quote-title"
                    gutterBottom
                    sx={{
                      textAlign: "left",
                      marginTop: "80px",
                      minHeight:{xs:'150px',md:'0'},
                      fontFamily: "blinker",
                      fontWeight: "400",
                      fontSize: {md:"56px" , sm:"24px"},
                      mb:{xs : 0 , md : '25px' ,lg : '33px'},
                    }} // Align question text to the left with margin from top
                  >
                    {currentQuestion.question}
                  </Typography>

                  <div className="flex min-h-[270] ">
                    {currentQuestion.id === 102 && <div className="flex lg:justify-between lg:flex-row flex-col-reverse items-center justify-center flex-branding" dir="rtl">
                      <div className="flex-image mt-20 ">
                        {defaultImage && <img alt="" src="./Brands/Brand - 1.svg" />}
                        {!defaultImage && <img alt="" src={selectedImage} />}
                      </div>
                      <Box
                        className="flex-branding-items "
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          marginTop: "20px",
                          width: "60%",
                          // marginBottom: "100px",
                          direction: "ltr"
                        }}
                      >
                        {currentQuestion.options.filter((option22) => !option22.isOtherAnswer).map((option) => (
                          <> <Chip
                            key={option.id}
                            label={option.text}
                            clickable
                            onClick={() => handleOptionChange(option.id, option.text)}
                            sx={{
                              fontSize:{lg : '24px' , md : '19px' , sm :'14px' },
                              margin: "5px",
                              
                              color : '#fff',
                              backgroundColor: answers[currentQuestionIndex]?.includes(option.id)
                                ? "#0A111D !important"
                                : "#344051 !important", // Dynamic backgroundColor
                              border: answers[currentQuestionIndex]?.includes(option.id)
                                ? "1px solid #1BE4A6"
                                : "1px solid #637083", // Change text color based on selection
                              borderRadius: "8px", // Rounded corners
                              fontFamily: "Blinker-Light",
                              // fontSize: "24px",
                              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                              //backgroundColor: "#344051",
                              fontWeight: "200",
                              padding: "10px",
                              height: "auto",
                              transition: "0.3s",
                              "&:hover": {
                                backgroundColor: "rgba(0,0,0,0) !important",
                                border: "1px solid #1BE4A6 !important",
                                color : '#1BE4A6 !important'
                              },
                            }}
                          />

                          </>
                        ))}

                      </Box>
                    </div>}

                    {currentQuestion.id !== 102 && <div className=" w-full lg:inline flex flex-col items-center min-h-[30vh] -mt-[50px] md:-mt-[20px] h-[30vh] md:h-auto md:overflow-hidden overflow-scroll ">
                      <Box
                      className="max-w-[100%] w-full "
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          
                          flexWrap: "wrap",
                          // marginTop: "20px",
                          maxHeight:{xs :'70vh', md : 'auto'},
                          // minHeight:'300px'
                          // marginBottom: "100px"
                        }}
                      >
                        {currentQuestion.options.filter((option22) => !option22.isOtherAnswer).map((option) => (
                          <Button
                            className=""
                            key={option.id}
                            // title={option.text}
                            clickable
                            onClick={() => handleOptionChange(option.id, option.text)}
                            sx={{
                              fontSize:{lg : '24px' , md : '19px' , sm :'14px' },
                              margin: "5px",
                              color : '#fff',
                              backgroundColor: answers[currentQuestionIndex]?.includes(option.id)
                                ? "#0A111D !important"
                                : "#344051 !important", // Dynamic backgroundColor
                              border: answers[currentQuestionIndex]?.includes(option.id)
                                ? "1px solid #1BE4A6"
                                : "1px solid #637083", // Change text color based on selection
                              borderRadius: "8px", // Rounded corners
                              fontFamily: "Blinker-Light",
                              // fontSize: "24px",
                              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                              //backgroundColor: "#344051",
                              fontWeight: "200",
                              padding: "10px",
                              // height: "auto",
                              transition: "0.3s",
                              textTransform:'none',
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                              "&:hover": {
                                backgroundColor: "rgba(0,0,0,0) !important",
                                border: "1px solid #1BE4A6 !important",
                                color : '#1BE4A6 !important'
                              },
                            }}
                          >{option.text}</Button>
                        ))}
                      </Box>
                    </div>}
                  </div>

                  {/* Navigation buttons: Back and Next */}
                  <Box
                    className="btn-next-parent items-center lg:items-start static lg:absolute"
                    sx={{
                      // position: "absolute",
                      bottom: "0",
                      right: "0",
                      display: "flex",
                      
                      flexDirection: "column",
                      gap: "10px",
                      direction: "rtl"
                    }}
                  >
                    {/* Next button */}
                    {/* {currentQuestion.isMultiple && !error ? <Typography className="error-text"> </Typography> : ''} */}
                    {currentQuestionIndex < questions.length - 1 ||
                      (additionalQuestionsLoaded && currentQuestionIndex < questions.length - 1) ? (
                      <Button
                        variant="contained"
                        onClick={nextQuestion}
                        className="btn-next"
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={submitAnswers}
                        className="btn-next">
                        Next
                      </Button>
                    )}

                    <div className="h-20px">
                    {error && questions[currentQuestionIndex].isMultiple ? (

<p className="error-text">
Please Select At Least One
</p> ):error &&( 
                  <p className="error-text">
                    Please Select One Option
                  </p>)}
                    </div>
                  </Box>
                </Box>
              </div>
            </div>
          </div>
        </Container >}
      </div>}
    </div>
  );
}

export default QuoteNew;