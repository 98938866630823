import React, { useEffect, useState } from "react";
// import SectionThree from "./SectionThree";
// import SectionFour from "../Static/SectionFour";
// import SectionFive from "../Static/SectionFive";
import { Box, Button, Card, CardActions, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import ServiceComponent from "./ServiceSectionComponent";

const ServicesSinglePage = () => {
  const data = [{
    title: 'SEO',
    description: 'Helping you rank higher on search engines is what we love to do!',
    image: '/s1.svg',
    backColor: '#76F998',
    link:'/services/seo'
},
{
    title: 'Brand Book Design',
    description: 'Want a distinctive look for your brand? We can help you create it!',
    image: '/s2.svg',
    backColor: '#83E0F5',
    link:'/services/branding'
},
{
    title: 'Visual Identity',
    description: 'Making your brand recognizable through visuals is our passion!',
    image: '/s3.svg',
    backColor: '#BBC6F3',
    link:'/services/#'
},
{
    title: 'Content Strategy',
    description: ' Planning engaging content for your audience is what we do best!',
    image: '/s4.svg',
    backColor: '#30CBEF',
    link:'/services/content-creation'
},
{
    title: 'Social Media Marketing',
    description: 'Helping you rank higher on search engines is what we love to do!',
    image: '/s5.svg',
    backColor: '#9AAAEE',
    link:'/services/#'
},
{
    title: 'Custom Web Design',
    description: 'Building a unique website tailored to your brand is our specialty!',
    image: '/s6.svg',
    backColor: '#1BE4A6',
    link:'/services/web-design'
},
{
    title: 'UI/UX',
    description: 'Designing user-friendly websites and apps is our expertise!',
    image: '/s7.svg',
    backColor: '#40FAC1',
    link:'/services/#'
},
{
    title: 'Campaign marketing',
    description: 'Running marketing campaigns to promote your product is what we excel at!',
    image: '/s8.svg',
    backColor: '#30CBEF',
    link:'/services/ads'
},
{
    title: 'Advertisement Video',
    description: 'Creating captivating videos to showcase your brand is our forte!',
    image: '/s9.svg',
    backColor: '#788DE8',
    link:'/services/ads'
},
]

    
    return (
<Box
          sx={{
            display: "flex",
            flexDirection:"column",
            height: "100%",
            maxWidth: "100%",
            alignItems:'center',
            alignContent:'center',
            mb:5
          }}
        >
          <Typography sx={{
            fontFamily:'Blinker',
            fontSize:{md:'48px' , xs:'24px'}
            ,fontWeight:'800',
            mb:4
          }}>Our Services</Typography>
          <Grid
            container
            sx={{
              display: { md: "flex", xs: "flex" },
              flexDirection: {lg:"row",md:'row',xs:'column'},
              height: {lg:'100%',md:'100%'},
              maxWidth:'1200px',
              alignItems:'center',
              alignContent:'center',
              justifyContent:'center',
              justifyItems:'center'
            }}
            spacing={5}
            
          >
            {data.map((x) => (
              <Grid sx={{}} item lg={4} md={6} xs={12}>
                <Link style={{
                  textDecoration:'none'
                }} to={x.link}>
                <ServiceComponent
                  title={x.title}
                  description={x.description}
                  image={x.image}
                  backColor={x.backColor}
                ></ServiceComponent>
                </Link>
              </Grid>
            ))}
          </Grid>
          </Box>
    )
}


export default ServicesSinglePage;