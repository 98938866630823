import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
// import myImg from "/Services/sec3/image.png"
function BrandingSectionThree() {

  const { lg, } = useMedia()

  return (
    <section className="py-16 md:py-32 flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row flex-col items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? 
            <LazyImage

              src="/Services/brand/sec3/image.png"
              alt="SEO Analysis on Mobile"
              // clss="relative rounded-2xl h-[728px] w-[512px] xl:w-[546px] xl:h-[780px] "
              clss="relative rounded-2xl w-[421px] h-[600px] xl:w-[481px] xl:h-[685px] "
            />
             : <LazyImage

              src="/Services/brand/sec3/resImage.png"
              alt="SEO Analysis on Mobile"
              clss="relative rounded-2xl  h-[260px] w-[356px] "
            />}

          </div>

          <div className="lg:pt-[92px] px-[68px] pt-[55px] bg-[black]/15 rounded-3xl lg:w-[490px] lg:h-[490px] w-[300px]  brand-card">
            <div className="mb-[38px] rounded-lg w-fit lg:flex lg:flex-row items-center">
              <LazyImage src='/Services/brand/sec3/book.png' alt='book' clss='w-8 h-8 lg:w-12 lg:h-12  lg:mb-0 mb-4 lg:mt-10' />
              <h2 className=" lg:w-[325px] w-[211px] text-[21px] lg:text-[31px] font-bold text-white  text-left lg:mt-10">
                Pitch Deck Design

              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-lg lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Creating engaging presentations that effectively communicate your brand's value proposition. <br />
              Use a pitch deck designed by professionals to create a memorable pitch. We aim for clarity, visual appeal, and persuasion. We want people to hear and remember your message.



            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default BrandingSectionThree;
