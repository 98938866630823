import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
function ContentSectionFour() {
  const {  lg, } = useMedia()
  return (
    <section className="py-16 md:py-32  flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row-reverse  flex-col  gap-8 items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? <img

              src="/Services/content/sec4/image.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl lg:w-[546px] lg:h-[780px]"
            /> : <img

              src="/Services/content/sec4/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl lg:w-[400px] 2xl:w-[600px] lg:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className=" lg:pt-[92px] px-[68px] pt-[45px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px]  content-card text-left">
            <div className="mb-[38px] rounded-lg  w-fit lg:flex lg:flex-row lg:items-center">
              <LazyImage src='/Services/content/sec4/smile.png' alt='book' clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10' />
              <h2 className="lg:w-[325px] w-[211px] text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
              Social Media Content Creation

              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
            Developing all elements of social media, including visual and video posts, eye-catching cover images, and engaging highlights. <br /> 
            Our social media content creation service is comprehensive, covering every aspect of your social media strategy. We create eye-catching posts and covers that resonate with your audience, boosting engagement and brand awareness.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContentSectionFour;
