import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
import { Button } from '@mui/material';

function AdsSectionFive() {

  const {  lg, } = useMedia()

  return (
    <section className="py-16 md:py-32 flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row flex-col items-center justify-center">
        <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? <LazyImage

              src="/Services/ads/sec5/image.png"
              alt="SEO Analysis on Mobile"

              clss="relative rounded-2xl lg:w-[546px] lg:h-[780px] "
            /> : <LazyImage

              src="/Services/ads/sec5/resImage.png"
              alt="SEO Analysis on Mobile"

              clss="relative rounded-2xl  h-[260px] w-[356px] "
            />}

          </div>

          <div className="lg: ads-sec2-card  lg:h-[790px]  lg:w-5/12 w-9/12  space-y-6 lg:mb-10 ">
          {/* Search icon and heading container */}
          <div className="flex items-start gap-4 overflow-hidden">
            {/* <img
              src="Services/seo-sec2-search.png"
              className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 flex-shrink-0"
              alt="Search Icon"
            /> */}
            <h2 className="text-[21px] lg:text-[31px] w-[325px] font-light text-left text-[#DEE2E6] leading-tight">
            How to Place an Order

            </h2>

          </div>
          <div className=" flex flex-col">
            <div className="">
              <div className="flex items-center gap-4 mb-4" >
                <LazyImage
                  src="/Services/ads/sec5/file.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[14px] lg:text-[19px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Select Ad Type
                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Choose from static, animated, video, or rich media ads based on your campaign goals.



              </p>
            </div>
            <div className="">
              <div className="flex items-center gap-4 mb-4" >
                <LazyImage
                  src="/Services/ads/sec5/dot.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[14px] lg:text-[19px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Define Target Audience
                                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Post-event analysis to measure the effectiveness of the advertising efforts and gather insights for future events.




              </p>
            </div>
            <div className="lg:pb-16">
              <div className="flex items-center gap-4 mb-4" >
                <LazyImage
                  src="/Services/ads/sec5/coffe.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[14px] lg:text-[19px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Consultation Request
                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Consultation Request Fill out a get peroposal or contact us for a personalized consultation to finalize your campaign details.
              </p>
            </div>

          </div>
          {/* Description text */}

        </div>
        
        </div>
        
      </div>
    </section>
  );
}

export default AdsSectionFive;
