
import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from '@mui/system';
import React from 'react';
import SeoCampaignSection1 from "./adsCampaignSection1";
import SeoCampaignSection2 from "./adsCampaignSection2";


const Image = styled('img')({});


const seoCampaign = () => {


  return (
    <Box height={'auto'}>
      <SeoCampaignSection1/>
                       {/* section2 */}
      <Box 
        sx={{
            display:{md:"flex"},
            justifyContent:"center",
          }}
        >
      <SeoCampaignSection2
          title="Bring Traffic"
          titleimage="title1.png"
          description="Bring more visitors to your site and convert them into loyal customers."
          price="1,000"
          discountPrice="600"
          features={[
            "Targeted campaigns to drive visitors to your site",
            "Conversion-focused landing pages to turn visitors into loyal customers",
          ]}
          isPopular="Most Popular"
        />
        <SeoCampaignSection2
          title="Full Growth"
          titleimage="title2.png"
          description="Maximize results with combined visibility and traffic strategies."
          price="2,800"
          discountPrice="1,500"
          features={[
            "Full visibility and traffic campaigns combined",
            "Monthly performance reports to track your success",
          ]}
          isPopular="Best Value"
        />
        <SeoCampaignSection2
          title="Get Notice"
          titleimage="title3.png"
          description="Get noticed on the platforms that matter most."
          price="650"
          discountPrice="350"
          features={[
            " Ads on search engines, social media, and websites",
            "Boost your brand’s visibility and attract new customers",
          ]}
          isPopular="Basic"
        /></Box>
          
      </Box>
  );
};

export default seoCampaign;
