
import { Box, Button, Grid, Typography } from "@mui/material";
import CountdownTimer from "./countdown";
import arrowCamp from './arrowCamp.png';
import p1 from './p1.png';
import p2 from './p2.png';
import vid from './SEO_Final.mp4';
import { styled } from '@mui/system';
import footer from './fotcamp.png';
import footer2 from './fotcamp2.png';
import tick from './tick.png';
import { Link, useSearchParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import React, { useEffect, useState } from 'react';



const Image = styled('img')({});
const Video = styled('video')({
 
  borderRadius: '20px',
  boxShadow: '0 0 15px #dee0e7',
});

const AspectRatioBox = styled(Box)(({ ratio }) => ({
  position: 'relative',
  width: '100%',
  paddingBottom: ratio ? `calc(100% / (${ratio}))` : '56.25%', // Default to 16:9
  '& > *': {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
  },
}));

const Campaign = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const location = useLocation();
  const [sent, setSent ] = useState (false)
  const [data, setData] = useState(null);
  const [code,setCode] = useState(null)
  const [queryParams] = useSearchParams();

  if(!sent)
    {
  setCode(queryParams.get('code'));  // replace 'myParam' with your query param key
  // 
  if (code) {
    
    
    setSent(true)
    axios.get(apiUrl+"/user-token/"+code)
    
  }}

  return (
    <>
    <Box height={'auto'}>
      <Grid container sx={{
        overflow:'hidden',
        // backgroundImage: `url("../back.png")`,
        backgroundImage:{xs:`url("../back2.png")`, md:`url("../back.png")`},
        height: "auto",
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        // backgroundSize: "cover",
        backgroundSize: {xs:"contain", md:"cover"},
       textAlign:'center',
        
        
      }}>
        
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: { xs: 4, md: 8 },
          textAlign: 'center'
        }}>
          <CountdownTimer targetDate={"3/05/2025"} />
          {/* <Typography sx={{
            mt: '76px',
            fontFamily: "blinker",
            fontSize: '162px' ,
            color: '#182233',
            fontWeight: '700',
            backgroundColor:"red",
            display:"flew",
            lineHeight: { xs: '120px', md: '120px' },
          }}>LEFT <br /> TO<img className="w-[100px] h-[100px]" src={arrowCamp} alt="Arrow" /></Typography> */}
          <Typography sx={{
            mt: '76px',
            fontFamily: "blinker",
            fontSize: { xs: '102px', md:'162px' },
            color: '#27DFA8',
            fontWeight: '700',
            lineHeight: { xs: '120px', md:'120px' },
          }}>LEFT</Typography>
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { xs:'102px', md:'162px' },
            // color: '#182233',
            color: '#27DFA8',
            fontWeight: '700',
            display:"flew",
            lineHeight: { xs: '120px', md:'120px' },
          }}>TO<img className=" mt-9 ml-4 md:mt-5 md:ml-1 w-[60px] h-[60px] md:w-[100px] md:h-[100px] " src={arrowCamp} alt="Arrow" /></Typography>
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { xs: '20px', md: '40px' },
            color: '#27DFA8',
            fontWeight: '800',
            lineHeight: { md: '80px' },
          }}>Transform Your SEO!</Typography>
          <Typography sx={{
            fontFamily: "blinker",
            fontSize: { md: '26px' },
            color: '#7A7D9C',
            fontWeight: '400',
            lineHeight: '20px',
          }}>Tomorrow’s success starts today.</Typography>
        </Grid>
        <Grid item lg={4} />
        <Grid container className="mt-10 md:mt-20 justify-center">
        <Grid item lg={2} />
          <Grid item  xs={12} md={8}>
          <Image src={p1} alt="p1"  sx={{ "@media (min-width: 1024px)": { marginLeft:"-150px" } }} />
          </Grid>
          <Grid item xs={12} md={8}>
          <Image src={p2} alt="p2" sx={{ "@media (min-width: 1024px)": { marginLeft:"180px" } }} />
            
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{
        mt: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: { xs: 2, md: 8 },
      }}>
        <Video sx={{
           width: {lg:'752px',md:'400px' , sm : '250px',xs:'350px'},
           height: {lg:'752px',md:'400px' , sm : '250px',xs:'350px'},
        }} autoPlay loop muted controls={false}>
          <source src={vid} type="video/mp4" />
        </Video>
      </Grid>
      <Box sx={{
        width: '100%',
        backgroundImage:{xs:`url(${footer2})`, md:`url(${footer})`},
        height: '100%',
        mt: 20,
        mb:2,
        backgroundRepeat: "no-repeat",
        backgroundPosition: 'center',
        backgroundSize: {xs:"contain", md:"cover"}
      }}>
        <Grid container sx={{ justifyContent: 'center',px:4
         }}>
          <Grid lg={6} item />
          <Grid lg={4} item sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom:{xs:"1600px", md:"150px"}
          }}>
            <Box sx={{
              width: { xs: '100%', md: '426px' },
              border: '1px solid #182233',
              backgroundColor: '#fff',
              borderRadius: '16px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2
            }}>
              <Box sx={{
                width: '100%',
                mt: '26px',
                borderRadius: '10px 10px 0 0',
                background: 'linear-gradient(45deg ,#fff , #9FFDE0 , #1BE4A6  )',
                textAlign: 'center',
                padding: 1
              }}>
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '400',
                  color: '#182233',
                  fontSize: { xs: '32px', md: '46px' }
                }}>FREE!</Typography>
              </Box>
              <Box sx={{ 
                mt: '25px' ,
                display:"flex",
                alignItems:"center"

                }}>
              <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '800',
                  color: '#182233',
                  fontSize: { xs: '27px', md: '37px' },
                  marginTop:"30px",
                }}>$</Typography>
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '800',
                  color: '#182233',
                  fontSize: { xs: '61px', md: '74px' }
                }}>0</Typography>
              </Box>
              <Box sx={{
                mt: '15px',
                textAlign: 'center'
              }}>
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '600',
                  color: '#182233',
                  fontSize: { xs: '18px', md: '23px' }
                }}>NO REGISTRATION NEEDED</Typography>
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '600',
                  color: '#3ff08e',
                  fontSize: { xs: '28px', md: '35px' }
                }}>JUST CLICK :)</Typography>
              </Box>
              <Box sx={{
                width: '100%',
                backgroundColor: '#F1F5FA',
                borderRadius: '10px',
                mt: '43px',
                alignContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 2
              }}>
                <img style={{
                  marginLeft: '14px',
                  width: '22px',
                  height: '22px'
                }} src={tick} alt="tick" />
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '400',
                  color: '#182233',
                  fontSize: { xs: '18px', md: '22px' },
                  ml: '14px'
                }}> Free SEO Consultation tailored to market trends</Typography>
              </Box>
              <Box sx={{
                width: '100%',
                backgroundColor: '#F1F5FA',
                borderRadius: '10px',
                mt: '10px',
                alignContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 2
              }}>
                <img style={{
                  marginLeft: '14px',
                  width: '22px',
                  height: '22px'
                }} src={tick} alt="tick" />
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '400',
                  color: '#182233',
                  fontSize: { xs: '18px', md: '22px' },
                  ml: '14px'
                }}> Free SEO Consultation tailored to market trends</Typography>
              </Box>
              <Box sx={{
                width: '100%',
                backgroundColor: '#F1F5FA',
                borderRadius: '10px',
                mt: '10px',
                alignContent: 'center',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: 2,
                mb: '20px'
              }}>
                <img style={{
                  marginLeft: '14px',
                  width: '22px',
                  height: '22px'
                }} src={tick} alt="tick" />
                <Typography sx={{
                  fontFamily: 'blinker',
                  fontWeight: '400',
                  color: '#182233',
                  fontSize: { xs: '18px', md: '22px' },
                  ml: '14px'
                }}> Free SEO Consultation tailored to market trends</Typography>
              </Box>
            </Box>
            <Link to={'/campaign/submit?code='+code}><Button sx={{
              mt: '25px',
              width: '360px',
              height: '86px',
              background: 'linear-gradient(90deg,#10F9B1, #38CBF9)',
              color: '#000',
              fontFamily: 'blinker',
              fontSize: { xs: '20px', md: '31px' },
              fontWeight: '600',
              borderRadius: '16px'
            }}>Let’s Rock It</Button></Link>
          </Grid>
        </Grid>
      </Box></Box>
    </>
  );
};

export default Campaign;
