import React, { useEffect, useRef, useState } from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
// import myImg from "/Services/sec3/image.png"
function WebSectionThree() {
  const { lg,} = useMedia()
  
  return (
    <section className="py-16 md:py-32 flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row flex-col items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg  ? <LazyImage
              
              // ref={imgRef}
              src="/Services/webD/sec3/image.png"
              alt="SEO Analysis on Mobile"
              clss="relative rounded-2xl h-[728px] w-[512px] xl:w-[546px] xl:h-[780px] "
              // className="relative rounded-2xl xl:w-[546px] xl:h-[780px] lg:mr-[164px]"
            />  :  <LazyImage

              src="/Services/webD/sec3/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl  h-[260px] w-[356px] "
            />}
            
          </div>

          <div className="lg:pt-[92px] px-[68px] pt-[55px] bg-[black]/15 rounded-3xl lg:w-[490px] lg:h-[490px] w-[300px]   web-sec2-card">
            <div className="mb-[38px] rounded-lg w-fit lg:flex lg:flex-row items-center">
              <LazyImage src='/Services/webD/sec3/badge.png' alt='book' clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 ' />
              <h2 className=" lg:w-[325px] w-[211px]  text-[21px] lg:text-[28px] font-bold text-[#DEE2E6] text-left ">
              Custom Web Application Development
              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px]  lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
            Developing tailored web applications, including CRM systems, e-commerce platforms, and more.  
              Our expertise in custom web application development allows us to create solutions tailored to your business needs, whether it's a CRM or any other specialized application.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebSectionThree;
