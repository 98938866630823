import { styled } from "@mui/system";
import React from "react";
import pc from "./assets/pc.png";
import back from "./assets/back.png";
import back2 from "./assets/back2.png";
import { Grid, Typography } from "@mui/material";

const Image = styled("img")({});
const SectionOne = () => {
  return (
    <Grid
      container
      sx={{
        overflow: "hidden",
        height: "100vh",
        display: "flex",
        flexDirection: { xs: "column-reverse", lg: "row" },
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      <Grid
        item
        lg={4}
        xs={12}
        sx={{
          overflow: "hidden",
          height: "100vh",
          justifyItems:{ lg:"left", xs:"center"},
          // , xs: "31px"
        }}
      >
        <Typography
          sx={{
            mt: { lg: 15, xs: 0 },
            ml: {xl:40 , lg: 20 },
            fontFamily: "blinker",
            fontSize: { lg: "48px", xs: "54px" },
            fontWeight:"700",
          }}
        >
          RUX
        </Typography>

        <Typography
          sx={{
            mt: { lg: 1, xs: 0 },
            ml: {xl:40 , lg: 20 },
            fontFamily: "blinker",
            fontSize: "35px",
            fontWeight:"100",
            
          }}
        >
          VISUAL IDENTITY{" "}
        </Typography>
      </Grid>
      <Grid item lg={8} xs={12}>
        <Image
          sx={{
            overflow: "hidden",
            width: { lg: "70%",  xs: "379px" },
            height: { lg: "70%", xs: "278px" },
            mt: {lg:"1%",xs:"60%"},
            ml: "1%",
          }}
          src={pc}
        ></Image>
      </Grid>
      <Image
        src={back}
        sx={{
          overflow: "hidden",
          display: "inline",
          position: "absolute",
          width: { lg: "1044.81px", xs: "379px" },
          top: "20px",
          right:0,
          zIndex: -5,
        }}
      ></Image>
      <Image
        src={back2}
        sx={{
          overflow: "hidden",
          display: "inline",
          position: "absolute",
          top: { lg: "-80px", xs: "-10px" },
          width: { lg: "90%", xs: "90%" },
          zIndex: -5,
          right:{ lg: "5%", xs: "0" },
          height: { lg: "90%", xs: "70%" },
          maskImage:
            "linear-gradient(to left, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 100%), linear-gradient(to bottom, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%)",
          maskComposite: "intersect",
          WebkitMaskImage:
            "linear-gradient(to left, rgba(0,0,0,0.7) 30%, rgba(0,0,0,0) 100%), linear-gradient(to bottom, rgba(0,0,0,1) 30%, rgba(0,0,0,0) 100%)",
          WebkitMaskComposite: "destination-in",
        }}
      ></Image>
    </Grid>
  );
};

export default SectionOne;
