import LazyImage from "../lazyImage";

export default function ContentSectionTwo() {
 
  return (
    <section className="w-full h-[105vh] bg-dark z-10 relative content-background flex justify-start items-center">
      <div className=" absolute z-20 w-full h-full seo-sec2-background-2" />
      <div className="w-[100%] max-w-[500px] flex flex-col items-center h-auto z-30  md:ml-19 lg:ml-20 xl:ml-44 rounded-lg">
        <div className="content-card w-[300px] flex flex-col justify-center lg:w-[585px] lg:h-[525px] p-6 lg:p-8 space-y-6 ">
          {/* Search icon and heading container */}
          <div className="xl:flex gap-4 lg:mb-14">
            <LazyImage
              src="/Services/content/sec2/a.png"
              clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
              alt="Search Icon"
            />
            <h2 className="w-[160px] lg:w-[250px]  text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
            Blog Writing and SEO Content

            </h2>
          </div>
          
          {/* Description text */}
          <p className="lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
          Producing well-researched articles optimized for search engines. < br/> 
          Quality content is essential for attracting and retaining customers. Our blog writing service creates engaging, SEO-optimized articles that will drive organic traffic to your website.

          </p>
        </div>
      </div>
    </section>
  )
}