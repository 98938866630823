
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
export default function AdsSectionThree() {
  const { lg,} = useMedia()
  
  return (
    <section className="w-full lg:h-[105vh] h-auto  z-10 relative  flex flex-col justify-end items-center ">
      <div className='mt-30 lg:mt-0'>
      <div className=" rounded-lg lg:max-w-[1440px] mt-10 lg:mt-0 flex  flex-col-reverse lg:flex-col items-center  ">
        <div className="ads-sec2-card h-full p-6 lg:p-8 space-y-6 mb-10 lg:w-[100%] w-11/12">
          {/* Search icon and heading container */}
          <div className="flex items-start gap-4 mb-16 ">
            {/* <img
              src="Services/seo-sec2-search.png"
              className="w-8 h-8 md:w-10 md:h-10 lg:w-12 lg:h-12 flex-shrink-0"
              alt="Search Icon"
            /> */}
            <h2 className="text-lg md:text-xl lg:text-3xl font-light text-[#DEE2E6] leading-tight text-left">
              Creative Campaign Advertising
            </h2>

          </div>
          <div className="lg:grid lg:grid-flow-col flex flex-col pb-4">
            <div className="mb-4">
              <div className="flex items-center gap-4 mb-8" >
                <LazyImage
                  src="/Services/ads/sec3/lamp.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12  lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className=" w-auto  text-[21px] lg:text-[28px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Concept Development
                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px]  lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Brainstorming and developing unique campaign ideas that align with your brand message.


              </p>
            </div>
            <div className="mb-4 lg:ml-6">
              <div className="flex items-center gap-4 mb-8" >
                <LazyImage
                  src="/Services/ads/sec3/brush.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12  lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[21px] lg:text-[28px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Creative Design
                                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px]  lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Designing visually appealing graphics and videos that capture attention and convey your message effectively.


              </p>
            </div>
            <div className="mb-4 lg:ml-6">
              <div className="flex items-center gap-4 mb-8" >
                <LazyImage
                  src="/Services/ads/sec3/cards.png"
                  clss='w-8 h-8 lg:w-12 lg:h-12  lg:mb-0 mb-4 lg:mt-10'
                  alt="Search Icon"
                />
                <h2 className="text-[21px] lg:text-[28px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
                Multi-Channel Integration
                </h2>

              </div>
              <p className="lg:w-[258px] w-[179px]  lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Implementing campaigns across various channels (online and offline) to ensure consistent messaging and broader reach.


              </p>
            </div>

          </div>
          {/* Description text */}

        </div>
        <div>

        {lg ? <img

src="/Services/ads/sec3/image.png"
alt="SEO Analysis on Mobile"

className="w-[1440px] h-[600px] lg:mt-[-25%]"
/> : <img

src="/Services/ads/sec3/resImage.png"
alt="SEO Analysis on Mobile"

className="relative rounded-2xl  h-[260px] w-[356px] "
/>}
          {/* <img src="/Services/ads/sec3/image.png" className="" alt="name"></img> */}
        </div>
      </div>
      </div>
    </section>
  )
}