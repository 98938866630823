import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
function SeoSectionFour() {
  const { lg, } = useMedia()
  return (
    <section className="py-16 md:py-32  flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row-reverse  flex-col  gap-8 items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? <LazyImage

              src="/Services/sec4/image.png"
              alt="SEO Analysis on Mobile"

              // clss="relative rounded-2xl lg:w-[546px] lg:h-[780px] "
            /> : <LazyImage

              src="/Services/sec4/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl lg:w-[400px] 2xl:w-[600px] lg:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className="  bg-[black]/15 rounded-3xl lg:w-[460px] lg:h-[430px] w-[300px] content-card text-left">
            <div className=" rounded-lg  w-fit lg:flex lg:flex-row lg:items-center">
              <LazyImage src='/Services/sec4/badge.png' alt='book' clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 ' />
              <h2 className="lg:w-[280px] text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left  ">
                Link Building and Content Marketing

              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
              Promoting quality content and creating backlinks to increase visibility.  <br />
              Our link-building strategies focus on acquiring high-quality backlinks that enhance your site's authority. With effective content marketing, we will boost your visibility and drive targeted traffic to your site.

            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SeoSectionFour;
