import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, TextField, useMediaQuery, useTheme } from '@mui/material';
import svg from '../SVG/discord-icon.svg';
import axios from 'axios';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import CalendarModal from '../modalTest/calendarModal';

const  MeetingForm = forwardRef((props, ref) => {
  const { date, formattedDate } = props;

  useImperativeHandle(ref, () => ({
    SubmitData,
  }));
  
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('md'));
  const apiUrl = process.env.REACT_APP_API_URL;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});
  const [meetingType , setMeetingType] = useState("google")
  const [load, setLoad] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [description, setDescription] = useState(null);

  const SubmitData = () => {
      const newErrors = {};
      // console.log(date.date)
      const d = new Date(date);

// Step 2: Convert the date to UTC
const utcDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000));

const isoStringWithZ = utcDate.toISOString();
console.log(isoStringWithZ)
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!name) {
          newErrors.name = 'Name is required';
      }
      console.log('name',name);
      if (!email) {
          newErrors.email = 'Email is required';
      } else if (!emailRegex.test(email)) {
          newErrors.email = 'Invalid email format';
      }
      if (description === null) {
        newErrors.description = 'Meeting agenda is required';
      }
      if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
          return;
      }
      try {
          setErrors({});
          setLoad(false)
          console.log(date)
          axios.post(apiUrl + '/meeting/new', {
              Date: isoStringWithZ,
              email,
              name,
              meetingType,
              description,

          }).then(x => {
              setLoad(true)
              setOpenModal(true)
          }).finally(x => {
              setOpenModal(true)
              setLoad(true)
          });
          
      } catch (error) {
          setLoad(true)
          alert('Error submitting form');
      }

  }
  return (

    <div className=" lg:-mt-36 w-[300px] lg:w-[450px] xl:mr-30 "  >
       {console.log('formattedDate2 ', formattedDate )}
      <CalendarModal openModal={openModal} name={name} formattedDate={formattedDate?.toLocaleString()} />
      <div className="relative flex flex-col items-center justify-center " id="logotopmeet">
          <img
              src={svg}
              alt="Logo"
              className=" flex justify-center items-center align-center h-20 w-20 m-2 "
          />
      </div> 
      
      <label className="question-form-label mb-1 block  text-base ">Your name:</label>
      <TextField type="text" id="calendarmode" fullWidth  value={name}
        onChange={(e) => {
          setName(e.target.value);
          // console.log('e.target.value',e.target.value);
        }
        }
        
        error={!!errors.name}
        helperText={errors.name} />

      <label className="question-form-label mt-4 mb-1 block text-base ">Email address:</label>
      <TextField type="email" id="calendarmode" required fullWidth  value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!errors.email}
        helperText={errors.email} />

      <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2 text-base " sx={{ color: 'white',fontSize:"16px", fontFamily:'blinker' ,marginTop:'16px'}}  >Meeting Type:</FormLabel>
        <RadioGroup
          row={isXs ? false : true}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={meetingType}
          onChange={(e) => setMeetingType(e.target.value)}
          // sx={{ marginTop:'10px'}}
        >
          <FormControlLabel defaultValue="google" control={<Radio onChange={() => setMeetingType('google')} value={'google'} sx={{ color: '#5e6b82', scale:'70%' , marginRight:'-10px', }} />} 
          sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', fontFamily: 'Blinker', }, }} label="Google meet" />
          <FormControlLabel value="skype"  control={<Radio value={'skype'} onChange={() => setMeetingType('skype')} sx={{ color: '#5e6b82', scale:'70%' , marginRight:'-10px'}} />} 
          sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', fontFamily: 'Blinker', }, }} label="Skype" />
          <FormControlLabel value="zoom"  control={<Radio value={'zoom'} onChange={() => setMeetingType('zoom')} sx={{ color: '#5e6b82', scale:'70%' , marginRight:'-10px', }} />} 
          sx={{ '& .MuiFormControlLabel-label': { fontSize: '16px', fontFamily: 'Blinker', }, }} label="Zoom" />
          
        </RadioGroup>
      </FormControl>
      <div className="my-2  ">
      <label className="text-base ">Meeting Agenda:</label>
      <TextField type="text" placeholder="What would you like to discuss?" fullWidth multiline
      value={description}
      required
      onChange={(e) => setDescription(e.target.value)}
      error={!!errors.description}
      helperText={errors.description}
       sx={{
      '& .MuiInputBase-root': {
        alignItems: 'flex-start',
        color:'white'
      },
      }} />
      </div>
      <div className=" flex items-end justify-center ">
        <Button 
            variant="contained" 
            sx={{backgroundColor:'#CCCCCC'}}
            className=" font-bold lg:w-1/3 w-[40vw] "
            // onClick={() => window.history.back()} 
            onClick={() => window.location.reload()} 
            style={{ marginTop: '20px' }}
        >
            back
          </Button>
           <Button
              disabled={!load}
              variant="contained"
              color="primary"
              className=" text-white  font-bold lg:w-1/3 w-[40vw] "
              onClick={SubmitData}
              sx={{
                  marginLeft:'1vw',
                }}
            >
              Submit
          </Button>
      </div>
    </div>
  );
})

export default MeetingForm;

