import { Box, Button } from "@mui/material";
import React  from "react";
import thanks from './thanks.png'
import mobile from './thankMobile.png'
import { Link } from "react-router-dom";

const Thank = ()  => {
    return(
        <Box sx={{
            width:'100%',
            height:'100vh',
            minHeight:'100%',
            background:{lg: `url(${thanks})`, xs:`url(${mobile})`},
            backgroundRepeat:{lg:'no-repeat' , xs:'no-repeat'},
            backgroundPosition:{lg: 'center right -100px',xs:'center' },
            backgroundSize:{lg: '90%',xs:'100%' },
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            justifyContent:'center',
            backgroundColor:"rgba(131,224,245,0.3) !important",

        }}>
            <Link to={'/'}>
            <Button
                    
                    variant="contained"
                    fullWidth
                   
                    sx={{

                        mt: 50,
                        background: 'linear-gradient(to right, #10F9B1, #38CBF9)',
                        color: '#000',
                        borderRadius: '10px',
                        border: '1px solid #000',
                        fontFamily: 'Blinker, sans-serif',
                        fontSize: '20px',
                        width:'200px',
                        fontWeight: 400,
                    }}
                >
                    Take A Tour
                </Button>
                </Link>
        </Box>
    )

}

export default Thank