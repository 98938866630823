import LazyImage from "../lazyImage";

export default function SeoSectionTwo() {
  return (
    <section className="w-full h-[105vh] bg-dark z-10 relative seo-sec2-background flex justify-center lg:justify-end  items-center">
      {/* <div className="seo-sec2-background-2 absolute z-20 w-full h-full " /> */}
      <div className=" lg:pt-[92px] px-[68px] pt-[45px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px]  content-card text-left lg:mr-[7vw] 2xl:mr-[15vw]">
          {/* Search icon and heading container */}
          <div className="lg:flex items-center gap-4">
            <LazyImage
              src="/Services/seo-sec2-search.png"
              clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
              alt="Search Icon"
            />
            <h2 className="text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
              Keyword Research and Analysis
            </h2>
          </div>
          
          {/* Description text */}
          <p className="lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
            Identifying optimal keywords to target for your business. Effective
            SEO starts with understanding what your audience is searching for.
            We find high-impact keywords that can boost your site's traffic,
            increase its visibility, and promote growth.
          </p>
      </div>
    </section>
  )
}