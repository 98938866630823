import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';
function WebSectionFour() {
  const {  lg } = useMedia()
  return (
    <section className="py-16 md:py-32  flex flex-col items-center">
      <div className="max-w-[1440px] ">
        <div className="flex lg:flex-row-reverse  flex-col  gap-8 items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? <LazyImage

              src="/Services/webD/sec4/image.png"
              alt="SEO Analysis on Mobile"
              clss="relative rounded-2xl h-[728px] w-[512px] xl:w-[546px] xl:h-[780px] "
              // className="relative rounded-2xl xl:w-[546px] xl:h-[780px] lg:ml-[164px]"
            /> : <LazyImage

              src="/Services/webD/sec4/resImage.png"
              alt="SEO Analysis on Mobile"
              // clss="relative rounded-2xl h-[728px] w-[512px] xl:w-[546px] xl:h-[780px] "
              // className="relative rounded-2xl xl:w-[400px] 2xl:w-[600px] xl:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className=" lg:pt-[92px] px-[68px] pt-[45px] bg-[black]/15 rounded-3xl lg:w-[490px] lg:h-[490px] w-[300px]  web-sec2-card text-left">
            <div className="mb-[38px] rounded-lg  w-fit lg:flex lg:flex-row lg:items-center">
              <LazyImage src='/Services/webD/sec4/badge.png' alt='book' clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 ' />
              <h2 className="lg:w-[325px] w-[211px]  text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left ">
              WordPress Website Development

              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px]  lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
            Creating effective and visually appealing websites with WordPress.  <br></br>
            We use WordPress to create responsive, user-friendly websites that are easy to manage and customize. Enjoy a seamless experience from design to launch and beyond.


            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebSectionFour;
