import { Button } from '@mui/material';
import React from 'react';

export default function FreeConsultationBtn({ text, href }) {
  return (
    <Button 
      variant="contained"
      href={href || "/meeting"} 
      sx={{
        fontFamily: 'blinker',
        fontWeight: '400',
        textTransform: 'none',
        fontSize: { xl: '22px', lg: '20px' },
      }}
      className="btn-header xl:w-[276px] xl:h-[75px] w-[228px] h-[52px] bg-[#10F9B1] rounded-lg text-black mt-4"
    >
      {text || "Free Consultation"}
    </Button>
  );
}
