import React from 'react'
import drop from '../SVG/drop.json'
import Lottie from 'react-lottie'

const defaultOptions = {
    loop: true,
    autoplay: true,
    
    animationData: drop,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


function AdsHeroSection() {
    return (
        <section className='w-full h-[auto] min-h-[100vh] bg-[#31B6D5] relative flex justify-center items-center z-[5] overflow-hidden'>
            <div className='absolute z-[1] w-[1531px] h-[1531px] rounded-full bg-[#BBC6F305] shadow-custom-1 top-[-5rem] '></div>
            <div className='absolute z-[1] w-[1152px] h-[1152px] rounded-full bg-[#BBC6F305] shadow-custom-2 top-[6rem] '></div>
            <div className='absolute z-[2] w-[771px] h-[771px] rounded-full bg-[#BBC6F305] shadow-custom-1 top-60 '></div>
            <div className="relative z-10 flex flex-col items-center gap-4 mt-20 md:mt-32">
        {/* <p className="ff-regular font-light text-lg md:text-2xl text-white text-center px-4 md:px-0"> */}
        <p className=" font-light text-[16px] lg:text-3xl text-white text-center px-[70px] 2xl:px-[600px] ">
        Creating captivating videos to showcase your brand is our forte!        </p>
        <p className="font-bold ff-bold text-5xl md:text-7xl text-center text-white">
          Ads
        </p>
        <img
          src="/Services/ads/ads.png"
          alt="SEO Illustration"
          className="w-36 h-36 md:w-[309px] md:h-[252px] mt-10"
        />
        <div className="cursor-pointer mt-4 w-16 h-16 lg:w-40 lg:h-40  ">
        <Lottie options={defaultOptions} />
        </div>
      </div>
        </section>
    )
}

export default AdsHeroSection