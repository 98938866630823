import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {  useParams } from 'react-router-dom';
import {  Typography, Card, CardContent, CardMedia, Container } from '@mui/material';
import { styled } from '@mui/system';
import '../Library/css/ck.css';
import '../Library/css/Blogs.css'
import parse from 'react-html-parser'
import { TabTitle } from '../utils/GeneralFunctions';
import { Helmet } from "react-helmet";
const StyledTypography = styled(Typography)`
  
`;


const StyledCard = styled(Card)`

`;
const apiUrl = process.env.REACT_APP_API_URL;
const PostDetails = () => {
  const { id } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    axios.get(apiUrl + `/post?category=blogs&path=${id}`)
      .then(response => {
        setPost(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the post!', error);
      });
  }, [id]);

  if (!post) {
    return <Typography>Loading...</Typography>;
  }
  const author = post.author;
  const content = post.content;
  const description = post.description
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = doc.querySelectorAll('img');
  const keywords = post.keywords
  return (
    <>
    <Helmet>
      <title>{post.title} | Beverly</title>
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href={'https://beverly.digital/blog/'  + {id}} />
      <meta name="author" content={author} />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
    </Helmet>
    <div role="presentation">
      
    </div>
    <Container sx={{
      width:'100%',
      mt:{xs:'60px', md : '80px'},
      mb:{lg:'100px' , xs : '60px'}
    }}>
      <StyledCard sx={{
      backgroundColor:'transparent !important'
    }}>
        {images.length > 0 && (
          <CardMedia
            component="img"
           
            image={post.banner}
            
            alt={post.title}
            sx={{
              
              borderRadius: '12px',
              display: 'block',
              marginTop: {xs:"75px" ,md:0},
              maxHeight:'400px',
            }}
          />
        )}
        <CardContent>
          <div className='w-100% flex flex-col  items-center'>
          <Typography className='ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred w-[100%] md:w-[80%] lg:w-[75%]'  variant='h1' sx={{
            color:'white',
            fontSize:{xl: '2.5em', lg: '2em',md:'1.5em' , xs :'1.25em'}
            
          }}  mb={3} align="center">

            {post.title}
          </Typography>
          </div>
          <StyledTypography
          id='postDetails'
          className='ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred'
          //  sx={{
          //   display:'inline',
          //   width:"100%",
          //   flexDirection:'column',
          //   alignItems:'center',
          //   alignContent:'center'
          // }}
          //   variant="body2"
          //   component="div"
            //  dangerouslySetInnerHTML={{ __html: doc }}
          >
            {parse(content)}
            </StyledTypography>
        </CardContent>
      </StyledCard>
    </Container>
    </>
  );
};

export default PostDetails;
