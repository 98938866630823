import React, {  Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Static/Home';
import About from './Components/About';
import Contact from './Components/Contact';
import "@fontsource/blinker/800.css";
import "@fontsource/blinker/600.css";
import "@fontsource/blinker/400.css";
import "@fontsource/blinker/200.css";
import "@fontsource/blinker/700.css";
// import Footer from './Components/mainFooter';
// import PostGrid from './Components/Posts';
import PostDetails from './Components/PostDetails';
import Works from './Components/Works/Works';
import WorksSingle from './Components/WorksSingle'
import ReactGA from 'react-ga4';
import RuxIndex from './Components/Works/ruxIndex';
// import GetQuote from './Components/GetQuote';
import Dentist from './Components/Works/dentist/Dentist';
import TagManager from 'react-gtm-module'
import Ticket from './Components/Ticket';
import ChatBot from './Components/chatbot';
import Nav from './Components/nav'
import './App.css'
import Campaign from "./Components/campaign";
// import okDialog from './Components/okDialog';
import UserData from './Components/campaign/userData';
import Thank from './Components/campaign/thanksPage';
import {  Typography, useMediaQuery, useTheme } from '@mui/material';
import QuoteNew from './Components/GetQuoteNew';
import SeoPage from './Components/seo';
import AdsPage from './Components/ads';
import WebDesignPage from './Components/webD';
import ContentDesignPage from './Components/contentCreation';
import BrandingDesignPage from './Components/branding';
// import CircleLoader from './Components/circleLoader';
import ScrollToTop from './Components/scrollToTop';
import MeetingPage from './Components//Meeting/meeting';
import ModalTesting from './Components/modalTest/modal';
import CircleLoader from './Components/circleLoader';
import BreadcrumbsComp from './Components/BreadCrumbComp';
// import {  ThemeProvider } from '@mui/material';
import Hotjar from '@hotjar/browser';
import useMedia from './hooks/useMedia';
import Footer from './Components/mainFooter';
// import RuxIndex from './Components/Works/ruxIndex';
import NotFoundPage from './Components/404/NotFoundPage';
import NewBlog from './Components/newblog/NewBlog';
import useOrientation from './hooks/useOrientation';
// import SeoCampaign from './Components/adsLandingPage/seoCampaign';
import SeoCampaign from './Components/adsLandingPage/adsCampaign';
import PathPostGrid from './Components/pathPost';
import ServicesSinglePage from './Components/Services'
ReactGA.initialize('G-69RRJC9W8H');


const siteId = 5234549;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);
const Layout = ({ children }) => {
  const location = useLocation();
  const theme = useTheme()
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  React.useEffect(() => {
    // if (location.pathname === '/' && !isXs) {
    //   document.body.classList.add('no-overflow');
    // } else {
    //   document.body.classList.remove('no-overflow');

    // }
  }, [isXs, location.pathname]);
  const [loading] = useState(false);

  useEffect(() => {

  }, []);
  return (
    <>
{/* <CircleLoader isVisible={loading}/> */}
    {!loading && (
       // Show loader while images and fonts are loading
    
    <div className="layout">
      {children}
    </div> )}

    </>
  );
};


const App = () => {
  // const theme = useTheme()
  const land = useOrientation()
  return (
    <>
    { console.log( useMediaQuery('(max-width: 1023px)')) }
    {
      useMediaQuery('(max-width: 1023px)') && land.includes('landscape') ? 
    

      <div style={{
        width:'100vw',
        height:'100vh',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        

      }}>
        <Typography sx={{
          fontFamily:'blinker',
          fontSize:'48px',
          fontWeight:'600'
        }}>Attention</Typography>
      <Typography sx={{
        fontFamily:'blinker',
        fontSize:'36px',
      }}>Please Rotate Your Phone
      </Typography>
    </div>
   
    : 
    <BrowserRouter>
    <MainApp />
  </BrowserRouter>
}
    </>
  );
};

const MainApp = () => {
  const { lg } = useMedia();
  const location = useLocation();
  const [isHome, setIsHome] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const resetCurrentPage = () => {
    setCurrentPage(0);
  };

  useEffect(() => {
    setIsHome(location.pathname === '/');
  }, [location.pathname]);
  // const Footer = React.lazy(() => import('./Components/mainFooter'));
  // const Nav = React.lazy(() => import('./Components/nav'));
  return (
    <>
      {/* <Suspense fallback={<CircleLoader isVisible={true} />}> */}
        <Nav onLogoClick={resetCurrentPage} />
      {/* </Suspense> */}
      <BreadcrumbsComp />
      <Suspense fallback={<CircleLoader isVisible={true} />}>
        <MainContent currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </Suspense>
      {(!isHome) && (
        // <Suspense fallback={<CircleLoader isVisible={true} />}>
          <Footer />
        // </Suspense>
      )}
    </>
  );
};


const MainContent = ({ currentPage, setCurrentPage }) => {
  
  // usePageViews()
  const PostGrid = React.lazy(() => import('./Components/Posts'));
  return (
    
    <Layout>
      {/* <ChatBot></ChatBot> */}
      <ScrollToTop />
      
      <Routes>
      
        <Route path="/" exact element={<Home currentPage={currentPage} setCurrentPage={setCurrentPage} />} />
        
        <Route path="/services/ads" element={<AdsPage />} />
        <Route path="/services/seo" element={<SeoPage />} />
        <Route path="/services/web-design" element={<WebDesignPage />} />
        <Route path="/services/content-creation" element={<ContentDesignPage />} />
        <Route path="/services/branding" element={<BrandingDesignPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/blog" element={<Suspense fallback={<CircleLoader isVisible={true}/>}><PostGrid /></Suspense>} />
        <Route path="/blog/:id" element={<PostDetails />} />
        <Route path="/case-study" element={<Works />} />
        <Route path="/case-study/:id" element={<WorksSingle />} />
        <Route path="/case-study/rux" element={<RuxIndex />} />
        <Route path="/case-study/dentist" element={<Dentist />} />
        <Route path="/quote" element={<QuoteNew />} />
        <Route path="/technical-support" element={<Ticket />} />
        <Route path="/chat" element={<ChatBot />} />
        <Route path="campaign/seo" element={<Campaign></Campaign>}></Route>
        <Route path="campaign/submit" element={<UserData></UserData>}></Route>
        <Route path="campaign/thanks" element={<Thank></Thank>}></Route>
        <Route path="meeting" element={<MeetingPage></MeetingPage>}></Route>
        <Route path="modal" element={<ModalTesting></ModalTesting>}></Route>
        <Route path="/404" element={<NotFoundPage/>} />
        <Route path="/newblog" element={<NewBlog/>} />
        <Route path="/adscampaign" element={<SeoCampaign/>} />
        <Route path="services" element={<ServicesSinglePage></ServicesSinglePage>} />
        <Route path="/:path/:id" element={<PostDetails />} />
        
        <Route path="/:path"element={<PathPostGrid />} />

        <Route
                    path="*"
                    element={<NotFoundPage  />}
                />
      </Routes>
      
      {/* {location.pathname !== '/' && <Footer />} */}
    </Layout>
  );
};

export default App;