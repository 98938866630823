import React from 'react';
import useMedia from '../../hooks/useMedia';
import LazyImage from '../lazyImage';


function ContentSectionFive() {

  const { lg, } = useMedia()
  return (
    <section className="py-16 md:py-32 flex justify-center items-center">
      <div className="max-w-[1440px]">
        <div className="flex lg:flex-row flex-col items-center justify-center">
          <div className="relative rounded-3xl overflow-hidden mb-5">
            {lg ? <img

              src="/Services/content/sec5/image.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl lg:w-[546px] lg:h-[780px] "
            /> : <img

              src="/Services/content/sec5/resImage.png"
              alt="SEO Analysis on Mobile"

              className="relative rounded-2xl lg:w-[400px] 2xl:w-[600px] lg:h-[650px] 2xl:h-[850px] h-[260px] w-[356px] "
            />}
          </div>

          <div className=" lg:pt-[92px] px-[68px] pt-[45px] bg-[black]/15 rounded-3xl lg:w-[512px] lg:h-[512px] w-[300px]  content-card text-left">
            <div className="mb-[38px] rounded-lg w-fit lg:flex lg:flex-row lg:items-center">
              <LazyImage src='/Services/content/sec5/note.png' alt='book' clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10' />
              <h2 className="lg:w-[300px]  text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left lg:mt-10 ">
              UX Writing for Advertisements
              </h2>
            </div>

            <p className="lg:w-[376px] w-[179px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
            Crafting persuasive messaging that enhances the user experience in marketing campaigns.  <br />
            Effective UX writing is crucial for successful advertisements. Our team writes clear, concise, and compelling messages that guide users on their journey and boost conversion rates.


            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContentSectionFive;
