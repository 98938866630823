import { Box, Grid ,Typography } from "@mui/material";
import React from "react";
import {styled} from "@mui/system"
import imgColor from './assets/Color.png'
import imgIcons from './assets/icons.png'
import imglogo from './assets/logo.png'
import imgLap from './assets/lap.png'
import imgCap from './assets/cap.png'
import imglogokdjd from './assets/gif.gif'
import imgBag from './assets/bag.png'
import imgShirt from './assets/shirt.png'
import imgBoard from './assets/board.png'
import imgphone from './assets/phone.png'
import imgMug from './assets/mug.png'
import imgThanks from './assets/thanks.png'


import imgpersona from './assets/persona.png'

const Image = styled('img')({
    
});

const SectionTwo = () =>{
    return (
        <Box sx={{
            display:"flex",
            overflow:'hidden',
            flexDirection:'column',
            alignItems:'center',
            height:'100%'
        }}>
            
        <Grid  container  sx={{
            width:{xs:"90%",md:'70%'},
            mt:10,
        }}>
            <Box sx={{
                // backgroundColor:"rgba(180, 180, 180, 0.1)",
                backgroundColor:"rgba(36,49,70, 0.8)",
                padding:"15px",
                margin:"0px 15px 50px 15px",
                borderRadius:"8px",

            }}>
            <Typography sx={{
                fontSize:{xs:"20px",md:"30px"},
                fontFamily:"blinker",
                textAlign:"justify",
            }}>Case Study:<br/> Rux Brand Book</Typography>
            <Typography sx={{
                fontSize:{xs:"16px",md:"20px"},
                fontFamily:"blinker",
                textAlign:"justify",
            }}>
            Rux, a B2B marketing and consulting firm for Arab markets, needed a strong brand identity. The challenge was to define its persona, stand out, and create a unique visual identity. A tailored brand book secured board approval and supported global expansion.</Typography>
            </Box>
            <Grid item lg={6} xs={12}>
                <Image sx={{
                    width:'100%',
                    // mt:{xs:"16px",md:"40px"},
                    padding:"15px",
                }} src={imgColor}></Image>
            </Grid>
            <Grid item lg={6} xs={12} >
                <Image sx={{
                    width:'100%',
                    // mt:{xs:"16px",md:"40px"},
                    padding:"15px",
                }}  src={imglogo}></Image>
            </Grid>
            <Grid item lg={6} xs={12} >
                <Image sx={{
                    width:'100%',
                    // mt:{xs:"16px",md:"40px"},
                    padding:"15px",
                }} src={imgIcons}></Image>
            </Grid>
            <Grid item lg={6} xs={12}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                }} src={imgpersona}></Image>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                    height:{md:'89%' , xs:'100%'}
                }} src={imglogokdjd}></Image>
            </Grid>   

            <Grid item xs={12} lg={8}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                }} src={imgLap}></Image>
            </Grid> 
        </Grid>
        <Grid sx={{
            width:{xs:"90%",md:'70%'},
            // padding:"15px",
            mt:{lg:-10, xs:'5px'},
        }} container >
         <Grid sx={{
            
         }} item xs={12} lg={8}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                }} src={imgBag}></Image>
            </Grid>  
            <Grid item xs={12} lg={4}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                    height:{md:'89%' , xs:'100%'}
                }} src={imgCap}></Image>
            </Grid> 

        </Grid> 

        <Grid sx={{
            width:{xs:"90%",md:'70%'},
            mt:{lg:-10,xs:'5px'},

        }} container >
         <Grid item xs={12} lg={4}>
                <Image sx={{
                    width:'100%',
                    height:'85%',
                    padding:"15px",
                }} src={imgMug}></Image>
            </Grid>  
            <Grid item xs={12} sx={{
                mt:{xs:'5px'},
                padding:"15px",
            }} lg={8}>
                <Image sx={{
                    width:'100%',
                    height:'85%',
                }} src={imgBoard}></Image>
            </Grid> 

        </Grid> 
        <Grid sx={{
            width:{xs:"90%",md:'70%'},
            mt:{lg:-7,xs:1},
            
        }} container >  
        <Grid item xs={12} lg={6}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                }} src={imgphone}></Image>
            </Grid>  
            <Grid item xs={12} lg={6}>
                <Image sx={{
                    width:'100%',
                    padding:"15px",
                }} src={imgShirt}></Image>
            </Grid> 

        </Grid> 
        <Grid sx={{
            width:{xs:"90%",md:'70%'},

        }} container >
        <Grid item xs={12} lg={12}>
                <Image sx={{
                    width:'90%',
                    flexDirection:'column',
                    alignItems:'center',
                    mb:20
                }} src={imgThanks}></Image>
            </Grid>  


        </Grid>
        </Box>
    )
}
export default SectionTwo;