import LazyImage from "../lazyImage";

export default function BrandingSectionTwo() {
  return (
    <section className="w-full h-auto brand-background lg:h-[105vh] bg-dark z-10  relative  flex lg:flex-row flex-col lg:justify-center items-center">
      <div className=" absolute h-[120vh] seo-sec2-background-2 lg:h-full z-20 w-full " />
      <div className="flex lg:flex-row flex-col-reverse  lg:w-[85%]   z-30  lg:max-w-[1440px] rounded-lg lg:items-start  lg:mb-10 mt-10">
        <div className="brand-card flex flex-col justify-center lg:mb-0 lg:mt-0 mb-40 mt-40 space-y-6 lg:h-[590px] lg:w-[400px] lg:mr-[25px] w-[300px] h-[470px]">
          {/* Search icon and heading container */}
          <div className="lg:flex items-center">
            <LazyImage
              src="/Services/brand/sec2/brush.png"
              clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
              alt="Search Icon"
            />
            <h2 className="w-[170px] lg:w-[400px] text-[21px] lg:text-[31px] font-bold text-[#DEE2E6] text-left ">
            Logo and Brand Identity Design 
            </h2>
          </div>
          
          {/* Description text */}
          <p className="text-[13px] lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
          Crafting unique logos and brand elements that embody your brand's essence.  <br />
          Our design team, with their specialized skills, excels in creating logos and brand identities that not only look great but also effectively convey your brand's message. A strong visual identity can make your business stand out and build trust with your audience.


          </p>
        </div>
        <div className=" brand-card flex flex-col justify-center  space-y-6  md:w-[696px] w-[300px] h-[470px] ">
          {/* Search icon and heading container */}
          <div className="lg:flex   items-center gap-4 ">
            <LazyImage
              src="/Services/brand/sec2/music.png"
              clss='w-8 h-8 lg:w-12 lg:h-12 lg:mr-4 lg:mb-0 mb-4 lg:mt-10'
              alt="Search Icon"
            />
            <h2 className="lg:w-[325px] w-[211px] text-[21px] lg:text-[31px] font-bold text-white text-left lg:mt-10 ">
            Brand Development and Storytelling  
            </h2>
          </div>
          
          {/* Description text */}
          <p className="lg:text-[17px] text-[13px] text-left text-[#DEE2E6] leading-relaxed">
          
Creating a reliable brand story that connects with your audience and builds an emotional connection.  <br />
We believe in the transformative power of storytelling. We develop brands by crafting narratives that engage your audience, build lasting relationships, boost brand loyalty, and enhance brand recognition.


          </p>
        </div>
      </div>
    </section>
  )
}